const forms = $('.form-control')
const inputGroups = $(forms).closest('.input-group')


forms.on('focus', function () {
    $(this).closest('.input-group').addClass('is-focus')
});

forms.on('blur', function () {
    $(this).closest('.input-group').removeClass('is-focus')
});

inputGroups.on('click', function (e) {
    if ($(e.target).hasClass('form-control')){
    }
    else{
        $(this).find('.form-control').first().focus()
    }
});
