
class BoxPromotionCounter {
    constructor(data) {
        this.container = data.container

        const remainingTime = this.getRemainingTime()

        this.insertTime(remainingTime)
    }

    getToday() {
        const now = new Date()
        const endDate = now.setHours(23, 59, 59, 99)
        this.getRemainingDays()
        return endDate
    }

    getRemainingDays() {
        const endDate = new Date(this.container.dataset.promoEndDate)

        const delta =  endDate - Date.now()
        const daysLeft = Math.floor(delta / (3600000 * 24))

        return daysLeft
    }

    getRemainingTime() {
        const endDate = this.getToday()
    
        const now = Date.now()

        const remainingTime = endDate - now 

        const hours = Math.floor(remainingTime / 3600000)
        const minutes = Math.floor((remainingTime - 3600000 * hours) / 60000)
        const seconds = Math.floor((remainingTime - (3600000 * hours + 60000 * minutes)) / 1000)
    
        const time = {
            hours: hours,
            minutes: minutes,
            seconds: seconds
        }
    
        return time
    }
    
    insertTime(time) {
        // console.log(this.container)
        const timeBoxes = $(this.container)
        const remainingDays = this.getRemainingDays()

        if(timeBoxes.find('[data-countdown-days]').length > 0) {
            timeBoxes.find('[data-countdown-days]').children('span')[1].innerText = Math.floor(remainingDays % 10)
            timeBoxes.find('[data-countdown-days]').children('span')[0].innerText = Math.floor(remainingDays / 10)
        }
        if(timeBoxes.find('[data-countdown-hours]').length > 0) {
            timeBoxes.find('[data-countdown-hours]').children('span')[1].innerText = time.hours % 10
            timeBoxes.find('[data-countdown-hours]').children('span')[0].innerText = Math.floor(time.hours / 10)
        }
        if(timeBoxes.find('[data-countdown-minutes]').length > 0) {
            timeBoxes.find('[data-countdown-minutes]').children('span')[1].innerText = time.minutes % 10
            timeBoxes.find('[data-countdown-minutes]').children('span')[0].innerText = Math.floor(time.minutes / 10)
        }
        if(timeBoxes.find('[data-countdown-seconds]').length > 0) {
            timeBoxes.find('[data-countdown-seconds]').children('span')[1].innerText = time.seconds % 10
            timeBoxes.find('[data-countdown-seconds]').children('span')[0].innerText = Math.floor(time.seconds / 10)    
        }
        
        this.initTimer()
    }
    
    initTimer() {
    
        setTimeout(() => {
            const newTime = this.getRemainingTime();
            this.insertTime(newTime);
    
        }, 1000)    
    }
}


const boxPromotionCounter = $('[data-promo-counter-landing-page]')

for (let i = 0; i < boxPromotionCounter.length; i++) {
    const element = boxPromotionCounter[i];
    new BoxPromotionCounter({container: element})
}