import anime from 'animejs';

export function fade(elements, delay = 0, duration = 480) {

    let animation = anime({
        targets: [...Array.from(elements)],
        opacity: {
            value: [0, 1],
            duration: duration
        },
        delay: delay,
        easing: 'easeInSine'
    });

    return animation.finished;
}
export function fadeOut(elements, delay = 0) {

    let animation = anime({
        targets: [...Array.from(elements)],
        opacity: {
            value: [0, 1],
            duration: 480
        },
        easing: 'easeInSine'
    });

    return animation.finished;
}


export function fadeInTop(elements, delay = 0, translateValue = 32) {

    let animation = anime({
        targets: [...Array.from(elements)],
        translateY: {
            value: [translateValue, 0],
            duration: 1000
        },
        opacity: {
            value: [0, 1],
            duration: 480
        },
        delay: delay,
        duration: 1000,
        easing: 'easeInSine'
    });

    return animation.finished;
}

export function fadeInBottom(elements, delay = 0, translateValue = -32) {

    let animation = anime({
        targets: [...Array.from(elements)],
        translateY: {
            value: [translateValue, 0],
            duration: 1000
        },
        opacity: {
            value: [0, 1],
            duration: 480
        },
        delay: delay,
        duration: 1000,
        easing: 'easeInSine'
    });

    return animation.finished;
}

export function fadeInTopFast(elements, delay = 0, translateValue = 32) {

    let animation = anime({
        targets: [...Array.from(elements)],
        translateY: {
            value: [translateValue, 0],
            duration: 300
        },
        opacity: {
            value: [0, 1],
            duration: 300
        },
        delay: delay,
        duration: 300,
        easing: 'easeInSine'
    });

    return animation.finished;
}



export function fadeInBottomFast(elements, delay = 0, translateValue = -32) {

    let animation = anime({
        targets: [...Array.from(elements)],
        translateY: {
            value: [translateValue, 0],
            duration: 300
        },
        opacity: {
            value: [0, 1],
            duration: 300
        },
        delay: delay,
        duration: 300,
        easing: 'easeInSine'
    });

    return animation.finished;
}


export function fadeOutTop(elements, delay = 0, translateValue = -32) {

    let animation = anime({
        targets: [...Array.from(elements)],
        translateY: {
            value: -8,
            duration: 150
        },
        opacity: {
            value: [1, 0],
            duration: 150
        },
        delay: delay,
        duration: 1000,
        easing: 'easeInSine'
    });

    return animation.finished;
}

export function fadeOutBottom(elements, delay = 0, translateValue = -32) {

    let animation = anime({
        targets: [...Array.from(elements)],
        translateY: {
            value: 8,
            duration: 150
        },
        opacity: {
            value: [1, 0],
            duration: 150
        },
        delay: delay,
        duration: 150,
        easing: 'easeInSine'
    });

    return animation.finished;
}


export function serverAnimation(server, topElement, centerElement, bottomElement, delay = 0) {

    anime({
        targets: [...Array.from(server)],
        opacity: {
            value: [0, 1],
            duration: 480
        },
        easing: 'cubicBezier(.16,0,0,1)',
        delay: delay
    });

    anime({
        targets: [...Array.from(topElement)],
        translateY: [32, 0],
        duration: 1000,
        easing: 'cubicBezier(.16,0,0,1)',
        delay: delay
    });

    anime({
        targets: [...Array.from(centerElement)],
        translateY: [16, 0],
        duration: 1000,
        easing: 'cubicBezier(.16,0,0,1)',
        delay: delay
    });

    let bottomElementAnimation = anime({
        targets: [...Array.from(bottomElement)],
        duration: 1000,
        easing: 'cubicBezier(.16,0,0,1)',
        delay: delay
    });

    return bottomElementAnimation.finished;
}

export function slideInTop(elements, delay = 0, translateValue = 32) {

    let animation = anime({
        targets: [...Array.from(elements)],
        translateY: {
            value: [translateValue, 0],
            duration: 1000
        },
        delay: delay,
        duration: 1000,
        easing: 'easeInSine'
    });

    return animation.finished;

}

export function slideInBottom(elements, delay = 0, translateValue = -32) {

    let animation = anime({
        targets: [...Array.from(elements)],
        translateY: {
            value: [translateValue, 0],
            duration: 1000
        },
        delay: delay,
        duration: 1000,
        easing: 'easeInSine'
    });

    return animation.finished;
}

export function pathAnimations(elements, delay = 0, duration) {

    let animation = anime({
        targets: [...Array.from(elements)],
        strokeDashoffset: [anime.setDashoffset, 0],
        delay: delay,
        duration: duration,
        easing: 'easeInSine',
    });

    return animation.finished;
}
