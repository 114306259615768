import breakpoint from './../utils/brakepoints';


var mobileFooter = {
    init: function () {
        this.cacheDom();
        this.bindEvents();
        this.setIsMobile();
    },
    cacheDom: function () {
        this.topFooter = $('.footer__site-map');
        this.footerTop = $('.footer__top');
        this.columnTitle = $('[data-toggle="footer-column"]');
    },
    bindEvents: function () {
        this.columnTitle.on('click', this.toggleList.bind(this));
    },
    toggleList: function (event) {
        var $element = $(event.currentTarget);

        if (!this.isMobile) {
            var $url = $element.data('url');
            if ($url !== undefined){
                window.location.href = $url;
            }
            return false;
        }


        if ($element.parent().hasClass('is-open')) {
            this.hideElement();
        } else {
            this.hideElement();
            this.showElement($element);
        }
    },
    showElement: function ($element) {
        var $column = $element.closest('div');

        if ($column.hasClass('is-open')) {
            $column.removeClass('is-open');
        } else {
            $column.addClass('is-open');
        }

    },
    hideElement: function () {
        this.topFooter.find('.is-open').removeClass('is-open');
        this.footerTop.find('.is-open').removeClass('is-open');
    },
    setIsMobile: function () {
        var that = this;
        that.isMobile = false;
        that.isTables = false;

    breakpoint.down('lg', {
        match() {
            that.isMobile = true;
        },
        unmatch() {
            that.isMobile = false;
        },
    });

    }
};

export default mobileFooter;
