
import util from './../util.js';
import superfish from 'superfish';
import enquire from 'enquire';

const SELECTORS = {
    container: '[data-nav]',
    closeLvl: '[data-nav-sub-close]'
};

const Default = {
    activeClass: 'is-open',
    openOn: 'click',
    preventClick: true,
    hideOnClickOutside: true,
    responsiveLayoutBreakpoint: 1012,
    delay: 600, // this option works only with set up openOn: hover
    slideUpSpeed: 200, // this option works only with set up openOn: hover
    navContext: 'ul',
    navLink: '> a',
    navItem: 'li.has-dropdown',
    popUpSelector: '.nav__dropdown',
    collapseAnimation: false
};

class Nav {
    constructor(container, options) {

        this.container = container;

        this.closeLvlButton = this.container.find(SELECTORS.closeLvl);

        this.options = options;
        this.getConfig();
        this.isResponsiveLayout = false;

        this.items = $(container).find(this.config.navItem);
        this.links = $(this.items).find(this.config.navLink);

        if (this.config.openOn == 'click') {
            this.container.removeClass('has-open-item');
        }

        this.setReponsiveLayout();

        this.bindEvents();

    }
    bindEvents() {

        this.links.on('click', this.checkStatus.bind(this));

        this.container.on('close-nav',()=>{
            this.hideActiveLvl();
        });

        if (this.config.openOn == 'click') {

            $(this.links).on('click', (event) => event.preventDefault());

        } else if (this.config.openOn == 'hover' || this.isResponsiveLayout) {

            if(this.config.preventClick == true) {   
                $(this.links).on('click', (event) => event.preventDefault());
            }
            this.initHoverNavgiation();

        }



        if ((this.config.hideOnClickOutside && this.config.openOn == 'click') || this.isResponsiveLayout) {

            $(document).on('click', this.clickOutside.bind(this));

        };




        $(this.links).on('click', (event) => {

            if (this.config.openOn == 'click' || this.isResponsiveLayout) {
                event.preventDefault();
            }

        });


        $(this.closeLvlButton).on('click', (event) => this.hideActiveLvl());

    }
    getConfig() {

        var dataConfig = this.container.data();

        if (dataConfig.options) {

            this.dataOptions = util.parseDataOptions(dataConfig.options);

        } else {
            this.dataOptions = {};
        }

        this.config = $.extend({}, Default, dataConfig, this.dataOptions, this.options);

    }
    checkStatus(event) {

        let item = $(event.currentTarget).closest(this.config.navItem);
        if (item.hasClass(this.config.activeClass)) {

            if (this.isResponsiveLayout || this.config.openOn == 'click') {

                this.hide(item);
            }

        } else {

            this.show(item);

        }

    }
    show(item) {

        this.hideActiveLvl(item);

        if (!this.isResponsiveLayout) {
            if (this.config.collapseAnimation) {
                item.find(this.config.popUpSelector).slideDown(this.config.slideUpSpeed);
            }
        }

        item.addClass(this.config.activeClass);
        this.container.addClass('has-open-item');

    }
    hide(item) {

        if (!this.isResponsiveLayout) {

            if (this.config.collapseAnimation) {

                item.find(this.config.popUpSelector).slideUp(this.config.slideUpSpeed, () => {
                    item.removeClass(this.config.activeClass);
                });

            } else {

                item.removeClass(this.config.activeClass);

            }
        } else {

            item.removeClass(this.config.activeClass);

        }
        item.prevObject.blur() // Remove focus from nav element when hiding subnav
        this.container.removeClass('has-open-item');


    }
    hideActiveLvl(item) {

        if (item) {
            var activeItem = item.closest(this.config.navContext).find('.' + this.config.activeClass);

            if (!this.isResponsiveLayout) {
                if (this.config.collapseAnimation) {

                    activeItem.find(this.config.popUpSelector).slideUp(this.config.slideUpSpeed, () => {
                        activeItem.removeClass(this.config.activeClass);
                    });

                } else {

                    activeItem.removeClass(this.config.activeClass);

                }
            } else {
                activeItem.removeClass(this.config.activeClass);
            }

        } else {
            this.clearStyles(this.container.find(this.config.popUpSelector));
            this.container.find('.' + this.config.activeClass).removeClass(this.config.activeClass);

        }

        this.container.removeClass('has-open-item');

    }
    initHoverNavgiation() {

        if (!this.isResponsiveLayout) {

            this.initSuperFish();

        } else {

            $(this.container).superfish('destroy');

        }

    }
    clearStyles(item) {
        $(item).attr('style', '');
    }
    initSuperFish() {

        let prefixedPopUpSelector = this.config.popUpSelector.slice(1, this.config.popUpSelector.length); //-------- Add Prefix --------
        prefixedPopUpSelector = ',.lu-' + prefixedPopUpSelector; //-------- Add Prefix --------


        $(this.container).superfish({
            hoverClass: this.config.activeClass,
            delay: this.config.delay,
            popUpSelector: this.config.popUpSelector + prefixedPopUpSelector, //-------- Add Prefix --------
            speed: 0,
            speedOut: 0,
            onInit: () => {

            },
            onShow: () => {
                this.container.addClass('has-open-item');
            },
            onHide: () => {
                this.container.removeClass('has-open-item');
            }

        });
    }
    clickOutside(event) {
        if (!$(event.target).closest(this.container).length) {
            if ($(this.container).is(":visible")) {
                this.hideActiveLvl();
            }
        }

    }
    setReponsiveLayout() {

        enquire.register("screen and (min-width: 0) and (max-width: " + this.config.responsiveLayoutBreakpoint + "px)", {
            match: () => {

                this.isResponsiveLayout = true;
                $(this.container).superfish('destroy');
                this.clearStyles(this.container.find(this.config.popUpSelector));

            },
            unmatch: () => {

                this.isResponsiveLayout = false;

                if (this.config.openOn == 'hover') {
                    this.initSuperFish();
                }
                this.clearStyles(this.container.find(this.config.popUpSelector));


            },
        });
    }
}

function initDataSelectors() {
    $(SELECTORS.container).each(function () {
        new Nav($(this));
    });
}

function initJqueryPlugin() {

    $.fn.luNav = function (options) {
        return this.each(function () {
            new Nav($(this), options);
        });
    };

}

const init = {
    initDataSelectors,
    initJqueryPlugin,
};

export default init;
