import brakepoints from './../utils/brakepoints';
class StickyNavbar {

    constructor() {

        this.container = $('[data-site-navbar]');
        if (!this.container.length || $('[data-resources-navbar]').length) {
            return;
        }
        this.initSticky();

    }

    initSticky() {

        var didScroll;
        var lastScrollTop = 0;
        var delta = 112;
        var mainNav = $('[data-nav]');

        if ($(window).scrollTop() > 0) {
            $('.site').removeClass('scrolled-top')
            $('.site').addClass('scroll-down')
        }
        else {
            $('.site').addClass('scrolled-top')
        }

        $(window).scroll(function (event) {
            didScroll = true;
        });

        setInterval(function () {
            if (didScroll) {
                hasScrolled();
                didScroll = false;
            }
        }, 50);


        function hasScrolled() {
            var navbarHeight = $('.site-navbar').outerHeight();
            var st = $(window).scrollTop();

            if (Math.abs(lastScrollTop - st) <= delta)
                return;
            if (st > lastScrollTop && st > navbarHeight) {
                // Scroll Down
                $('body').removeClass('scroll-up scrolled-top scroll-up--menu-open').addClass('scroll-down');
                if ($('.site-navbar nav.navbar__menu.is-open').length > 0) {
                    $('body').addClass('scroll-down--menu-open');
                }
                if ($('.banner--promotion').length && $('.banner--promotion').is(':visible')){
                    var alertheight = parseFloat(parseFloat($('.banner--promotion').outerHeight()).toFixed(0));
                    $('.section--legal .section__sidebar').css('top', alertheight + 20);
                }
            } else {
                // Scroll Up
                if (st + $(window).height() < $(document).height()) {

                    $('body').removeClass('scroll-down scrolled-top scroll-down--menu-open').addClass('scroll-up');
                    if ($('.banner--promotion').length && $('.banner--promotion').is(':visible')){
                        var alertheight = parseFloat(parseFloat($('.banner--promotion').outerHeight()).toFixed(0));
                        $('.section--legal .section__sidebar').css('top', alertheight + 112);
                    }
                    if ($('.site-navbar nav.navbar__menu.is-open').length > 0) {
                        $('body').addClass('scroll-up--menu-open');
                    }
                    if (brakepoints.is('desktop')) {

                        mainNav.trigger('close-nav')
                        $('[data-main-menu], [data-user-menu]').trigger('close-nav');

                    }


                }
            }

            if (st <= delta) {
                $('body').removeClass('scroll-up scroll-up--menu-open');
                $('body').removeClass('scroll-down scroll-down--menu-open');
                $('body').addClass('scrolled-top');
            }

            lastScrollTop = st;
        }
    }

}


export default {
    init() {
        new StickyNavbar();
    }
}
