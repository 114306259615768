$('.section--banner-footer.has-shapes').prev('section').addClass('section--last')

$(document).ready(function () {
    var countDownDate = new Date($("[data-countdown]").data('countdown')).getTime();

    var x = setInterval(function() {

        var now = new Date().getTime();

        var distance = countDownDate - now;

        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        if(document.getElementById("box--promotion__days")) {
            document.getElementById("box--promotion__days").innerHTML = days + " <span class=\"p-1\"> Days</span>";
            document.getElementById("box--promotion__hours").innerHTML = hours + " <span class=\"p-1\"> Hours</span>";
        }
    }, 1000);
});


var inputs = document.querySelectorAll( '.form-control--file-input input');

inputs.forEach(input => {
    input.addEventListener( 'change', e => {
        var fileName = '';

        fileName = e.target.value.split( '\\' ).pop()

        if( fileName ) {
            document.querySelector('.form-control__uploaded-file').innerHTML = fileName
        }
        else {
            document.querySelector('.form-control__uploaded-file').innerHTML = ""
        }
    });
})


const pricingDiscountItems = document.querySelectorAll('.section--packages .package .pricing__discount, .section--packages .pt .pricing__discount')
const pricingButtons = document.querySelectorAll('.section--packages .pt .pt__cell--button .btn')

pricingDiscountItems.forEach(item => {
    item.onclick = function(e) {
        e.stopPropagation()
        e.preventDefault()
    }
});

pricingButtons.forEach(item => {
    item.onclick = function(e) {
        e.stopPropagation()
    }
});

