/**
 * --------------------------------------------------------------------------
 * Name: Scroll To(layersUI)
 * Version: Alpha
 * License: -
 * Website: http://layersui.com/
 * --------------------------------------------------------------------------
 */




import util from './util.js';
import SmoothScroll from 'smooth-scroll'

const SELECTORS = {
    link: '[data-scroll-to]',
};

const Default = {
    element: '#',
    offset: 10,
    speed: 400,
    updateurl:true
};

class ScrollTo {
    constructor(element, options) {
        this.element = element;
        this.options = options;

        this.getConfig();

        this.hash = $(element).attr('href') || this.config.element;
        this.target = $(this.hash);

        if (!this.target.length) {
            return;
        }
        this.initScroll();
        this.bindEvents();

    }
    bindEvents() {

        $(this.element).on('click', (event) => this.scrollTo(event));

    }

    getConfig() {

        let dataConfig = this.element.data();

        if (dataConfig.options) {
            this.dataOptions = util.parseDataOptions(dataConfig.options);
        } else {
            this.dataOptions = {};
        }
        this.config = $.extend({}, Default, dataConfig, this.dataOptions, this.options);

    }
    initScroll() {

        this.smothScroll = new SmoothScroll();
        this.smothScrollOptions = {
            speed: this.config.speed,
            easing: 'easeOutCubic',
            offset: this.config.offset,
            updateURL: this.config.updateURL
        }

    }
    scrollTo(event){
        event.preventDefault();
        this.smothScroll.animateScroll(this.target[0],this.element[0], this.smothScrollOptions);
    }
}

function initDataSelectors() {

    $(SELECTORS.link).each(function () {

        new ScrollTo($(this));

    });

}

function initJqueryPlugin() {
    $.fn.luScrollTo = function (options) {
        return this.each(function () {
            new ScrollTo($(this), options);
        });
    };
}

const init = {
    initDataSelectors,
    initJqueryPlugin,
};


export default init;
