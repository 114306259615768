
import toastr from 'toastr';

//templates for alserts
const fullWidthAlert = ` 
<div class="alert alert--{{status}} alert--{{size}} alert--fixed alert--fixed-{{position}} is-active has-icon">
    <div class="container">
        <div class="alert__body">
            <div class="alert__title">{{title}}</div> 
            <p>{{body}}</p>
        </div>
        {{actions}}             
    </div>                  
</div> `;

const cornerAlert = `
<div class="alert alert--{{status}} alert--{{size}} alert--outline alert--float alert--border-left has-icon">
    <div class="alert__body">
        <div class="alert__title">{{title}}</div>
        <p>{{body}}</p>
    </div>                             
</div>`;

const Default = {
    openOn: 'click', //init, hover, click
    position: "right-top",
    status: "danger",
    title: 'title',
    body:'',
    classes:'',
    timeOut: 8000,
    size:'',
    template: null,
    newestOnTop: false,
    closeButton: false
};    
class Notification {
    
    constructor(options){
        this.options = options;

        this.getConfig();
        this.configurePlugin();
        this.configureTemplate();
        this.show();
    }
    getConfig(){

         this.config = $.extend({}, Default, this.options);
         
    }
    configurePlugin(){

        toastr.options = {
            "positionClass": "toast--" + this.config.position,
            "timeOut": this.config.timeOut,
            "newestOnTop": this.config.newestOnTop,
            "closeButton": this.config.closeButton,
        };

    }
    configureTemplate(){

        if(this.config.template !== null){
            this.template = this.config.template;
        }else{
            this.template = cornerAlert;
        }

        this.template = this.template.replace('{{status}}', this.config.status);
        this.template = this.template.replace('{{position}}', this.config.position);
        this.template = this.template.replace('{{title}}', this.config.title);
        this.template = this.template.replace('{{body}}', this.config.body);
        this.template = this.template.replace('{{classes}}', this.config.classes);
        this.template = this.template.replace('{{size}}', this.config.size);

    }
    show(){
        toastr.success(this.template);
    }
}
function init(){
    window.luNotification = function(options){
        new Notification(options);
    } 
}
export default {
    init
};