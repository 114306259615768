
function changePricing(cycle, cycleName) {

    const prices = [...document.querySelectorAll('[data-price]')];
    const pricingPeriod = [...document.querySelectorAll('[data-pricing-period]')];
    const pricesOld = [...document.querySelectorAll('[data-price-old]')];
    const priceDiscount = [...document.querySelectorAll('[data-price-discount]')];
    const planLinks = [...document.querySelectorAll('[data-plan-link]')];
    const planDesc = [...document.querySelectorAll('[data-plan-description]')];
    const planLabels = [...document.querySelectorAll('[data-plan-labels]')];
    
    for (let i = 0; i < prices.length; i++) {
        
        if ($(prices[i]).data('price')[cycle - 1] == "Not Available"){
            $(prices[i]).closest('.package').addClass('package--disabled');
            $(prices[i]).closest('.pt__row-content').addClass('not-available');
            $(prices[i]).closest('.plan__header').addClass('plan__header--disabled');
        }
        else{
            $(prices[i]).closest('.package').removeClass('package--disabled');
            $(prices[i]).closest('.pt__row-content').removeClass('not-available');
            $(prices[i]).closest('.plan__header').removeClass('plan__header--disabled');
            prices[i].textContent = $(prices[i]).data('price')[cycle - 1];
        }
        $(prices[i]).closest('.package').removeClass('monthly quarterly semiannually annually biennially triennially').addClass(cycleName.toLowerCase());
        $(prices[i]).closest('.plan').removeClass('monthly quarterly semiannually annually biennially triennially').addClass(cycleName.toLowerCase());
    }

    for (let i = 0; i < pricingPeriod.length; i++) {
        pricingPeriod[i].textContent = $(pricingPeriod[i]).data('pricing-period')[cycle - 1];
    }

    for (let i = 0; i < pricesOld.length; i++) {
        pricesOld[i].textContent = $(pricesOld[i]).data('price-old')[cycle - 1];
    }

    for (let i = 0; i < priceDiscount.length; i++) {
        if (parseInt($(priceDiscount[i]).data('price-discount')[cycle - 1]) === 0){
            $(priceDiscount[i]).closest('.pricing__discount').addClass('is-hidden');
        }
        else{
            $(priceDiscount[i]).closest('.pricing__discount').removeClass('is-hidden');
            priceDiscount[i].textContent = $(priceDiscount[i]).data('price-discount')[cycle - 1];
        }   
    }

    for (let i = 0; i < planLinks.length; i++) {
        planLinks[i].href = $(planLinks[i]).data('href')+$(planLinks[i]).data('plan-link')[cycle - 1];
    }
    for (let i = 0; i < planDesc.length; i++) {
        planDesc[i].textContent = $(planDesc[i]).data('plan-description')[cycle - 1];
    }
    for (let i = 0; i < planLabels.length; i++) {
        planLabels[i].textContent = $(planLabels[i]).data('plan-labels')[cycle - 1];
        let color = $(planLabels[i]).data('plan-labels-colors')[cycle -1];
        let isPt = $(planLabels[i]).closest('.pt__body');
        let ptClass = "";
        if (isPt.length){
            ptClass = "pt__label ";
        }

        if (color == ""){
            planLabels[i].className = ptClass+ 'package__label label label--lg is-hidden';
        }
        else{
            planLabels[i].className = ptClass + 'package__label label label--'+color+' label--lg';
        }
    }
}

class pricingUpdater {
    constructor(container) {
        this.initUpdater();
    }
    initUpdater() {
        [...document.querySelectorAll('[data-change-pricing]')].map(changePricingButton => {
            changePricingButton.addEventListener('click', function () {
                let currentPricing = document.querySelector('.nav .nav__item.is-active a[data-change-pricing]').dataset.changePricing;
                let currentCycleName = document.querySelector('.nav .nav__item.is-active a[data-change-pricing]').innerText;
                changePricing(currentPricing, currentCycleName)
                
            })
        })
        if (document.querySelector('.nav .nav__item.is-active a[data-change-pricing]')) {
            let currentPricing = document.querySelector('.nav .nav__item.is-active a[data-change-pricing]').dataset.changePricing;
            let currentCycleName = document.querySelector('.nav .nav__item.is-active a[data-change-pricing]').innerText;
            changePricing(currentPricing, currentCycleName)
        }
    }
}

export default {
    init() {
        new pricingUpdater();
    },
};
