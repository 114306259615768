
import util from './../util.js';

const SELECTORS = {
    container: '[data-check-container]',
    checkAll: '[data-check-all]',
    childs: 'input:checkbox:enabled',
    counterView: '[data-checkbox-counter]'
};

const Default = {

};
class CheckAll{
    constructor(container, options){
        this.container = container;
        this.checkAllBtn = this.container.find(SELECTORS.checkAll);
        this.childs = this.container.find(SELECTORS.childs).not(SELECTORS.checkAll);
        this.counterView = this.container.find(SELECTORS.counterView);
        this.checkCounter = 0;
        this.options = options;

        this.getConfig();
        this.initCounter();
        this.bindEvents();
        this.onInit();
    }
    bindEvents(){
        this.checkAllBtn.on('change',this.checkAll.bind(this));
        this.childs.on('change',this.updateCounter.bind(this));
    }
    getConfig(){
        var dataConfig  = this.container.data();

        if(dataConfig.options){
            this.dataOptions = util.parseDataOptions(dataConfig.options);
        }else{
            this.dataOptions = {};
        }
        this.config = $.extend({}, Default, dataConfig, this.dataOptions, this.options);
    }
    checkAll(event){
        this.childs.prop('checked', event.target.checked);
        if (event.target.checked) {
            this.checkCounter = this.childs.length;
        } else {
            this.checkCounter = 0;
        }
        this.updateCounterView();
    }
    toggleCheckAllBtn(){

        if(this.childs.length == this.checkCounter){
            this.checkAllBtn.prop('checked', true);
        }else{
            this.checkAllBtn.prop('checked', false);
        }
        if (typeof this.config.onChangeAll === 'function') {
            this.config.onChangeAll(this.container, this.checkCounter);
        }
    }
    initCounter(){

        if(this.checkAllBtn.is(':checked')){
            this.checkCounter = this.childs.length;
            this.childs.prop('checked', true);
        }else{
            this.checkCounter = this.childs.filter(':checked').length;
        }
    }
    updateCounter(event){
        if (event.target.checked) {
            this.checkCounter++;
            this.toggleCheckAllBtn();
        } else {
            this.checkCounter--;
            this.toggleCheckAllBtn();
        }
        this.updateCounterView();
    }
    updateCounterView(){

        if (typeof this.config.onChange === 'function') {
            this.config.onChange(this.container, this.checkCounter);
        }
        this.counterView.text(this.checkCounter);
    }
    onInit(){
        this.updateCounterView();
    }
}

function initDataSelectors(){

    $(SELECTORS.container).each(function(){
        new CheckAll($(this));
    });

}

function initJqueryPlugin(){
    $.fn.luCheckAll = function( options ) {
        return this.each(function() {
            let checkAll = new CheckAll($(this), options);
            this.updateCounter = checkAll.updateCounter;
        });
    };
}

const init = {
    initDataSelectors,
    initJqueryPlugin
};



export default init;

