import Swiper from '../../../assets-ui/js/plugins/swiper';


class logoDbSlider{
    constructor(container){
        this.container = container;
        this.initSlider();
    }
    initSlider(){
        this.logoDbSlider = new Swiper('.swiper-logos-db', {
            spaceBetween: 30,
            slidesPerView: 7,
            loop: true,
            wrapperClass: 'logos-db--wrapper',
            breakpoints: {
                1387: {
                    slidesPerView: 5,
                },
                751: {
                    slidesPerView: 3,
                    initialSlide: 2,
                },
                513: {
                    slidesPerView: 2,
                }
            }
        });
    }
}

export default {
    init() {
        new logoDbSlider($('.swiper-logos-db'));
    }
};
