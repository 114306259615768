import Cookies from 'js-cookie';


var bannerPromo = {
    init: function () {
        var that = this;
        //default status
        var status = false;
        this.functions();
        let url = window.location.pathname;

        $.getJSON("/templates/lagom2/assets/json/banner-promo.json", function (data) {
            //check alerts status and cookie id
            if (data.status == undefined || data.status == "" || Cookies.get('bannerPromo') == data.id) {
                status = false;
            }
            else {
                status = data.status;
            }
            if (status === true) {
                // that.functions();
            }
            else {
                that.removeAlert();
            }
        });

    },

    setTop: function () {
        var alertheight = parseFloat($('.banner--promotion').outerHeight()).toFixed(0);
        $('.banner--promotion').css('top', -(alertheight));
    },
    functions: function () {
        this.resizeContent();
        var that = this;
        $('.banner--promotion [data-action="close"]').on('click', this.closeAlert.bind(this));
        setTimeout(function () {
            that.setTop();
            that.showAlert();
        }, 2500);
    },
    showAlert: function () {
        var banner = document.getElementById("banner--promotion");
        $(banner).css("display", "block");

        var alertheight = parseFloat(parseFloat($('.banner--promotion').outerHeight()).toFixed(0));
        $('.banner--promotion').css('top', -(alertheight));
        if ($('#plan-header-sticky')) {
            $('#plan-header-sticky').css('top', (alertheight) + 'px');
        }
        $('body').animate({
            'padding-top': alertheight
        }, 520);
        $('.banner--promotion').animate({
            'top': 0
        }, 500);
        $('.banner--promotion .banner__body').animate({
            'padding-top': alertheight
        }, 500);
        $('.site-navbar').animate({
            'top': alertheight
        }, 500);
        if ($('body').hasClass('scroll-up')){
            $('.section--legal .section__sidebar').animate({
                'top': alertheight + 112
            }, 200);
        }
        else{
            $('.section--legal .section__sidebar').animate({
                'top': alertheight + 20
            }, 200);
        }
        if ($('body').width() < 1011) {
            $('.navbar__menu').animate({
                'top': alertheight + 68
            }, 500);
        }
        $('#btn--collapse').on("click", function() {
            $('.banner__body').toggleClass("is-open");
            $(".banner--right").toggleClass('is-open');
            $('html').toggleClass('top-promotion-is-open');
        })
    },
    resizeContent: function () {
        $(window).on('resize', function () {
            var alertheight = parseFloat(parseFloat($('.banner--promotion').outerHeight()).toFixed(0));
            if ($('body').width() < 752) {
                $('body').animate({
                    'padding-top': alertheight
                }, 0);
            } else {
                $('body').animate({
                    'padding-top': alertheight
                }, 0);
                $('body').animate({
                    'margin-top': 0
                }, 0);
            }
            $('.banner--promotion .banner__body').animate({
                'padding-top': alertheight
            }, 0);
            $('.site-navbar').animate({
                'top': alertheight
            }, 0);
            if ($('#plan-header-sticky')) {
                $('#plan-header-sticky').css('top', (alertheight) + 'px');
            }
            if ($('body').hasClass('scroll-up')){
                $('.section--legal .section__sidebar').css('top', alertheight + 112);
            }
            else{
                $('.section--legal .section__sidebar').css('top', alertheight + 20);
            }
            $('.navbar__menu').css('top', alertheight + 70 + 'px')
        });
    },
    closeAlert: function () {
        var that = this;
        var alertheight = parseFloat($('.banner--promotion').outerHeight()).toFixed(0);
        $('body').animate({
            'padding-top': 0
        }, 500);
        $('.banner--promotion').animate({
            'top': -(alertheight)
        }, 510);
        $('.site-navbar').animate({
            'top': 0
        }, 500);
        if ($('#plan-header-sticky')) {
            $('#plan-header-sticky').animate({
                'top': 0
            }, 500);
        }
        if ($('body').hasClass('scroll-up')){
            $('.section--legal .section__sidebar').animate({
                'top': 132
            }, 200);
        }
        else{
            $('.section--legal .section__sidebar').animate({
                'top': 20
            }, 200);
        }
        $('html').removeClass('top-promotion-is-open');
        $('.navbar__menu').css('top', 70 + 'px')
        setTimeout(function () {
            $('.navbar--main').css('top', '');
            $('.body').css('padding-top', '');
            $('.section--legal .section__sidebar').removeAttr('style');
            that.removeAlert();
        }, 520);
        that.setCookie();
    },
    setCookie: function () {
        let url = window.location.pathname;
        $.getJSON("/templates/lagom2/assets/json/banner-promo.json", function (data) {
            //set default values when json values are empty
            if (data.id == undefined || data.id == "") {
                var id = '1';
            }
            else {
                var id = data.id;
            }
            if (data.cookie - time == undefined || data.cookie - time == "") {
                var time = '30';
            }
            else {
                var time = data.cookie - time;
            }
            Cookies.set('bannerPromo', id, { expires: time });
        });
    },
    removeAlert: function () {
        $('.banner--promotion').remove();
    }

}

export default bannerPromo

