// let validate = require("validate.js");

// let constraints = {
//     'form[name]': {
//         presence: true,
//         length: {
//             minimum: 3,
//             maximum: 20,
//         },
//     },
//     'form[email]': {
//         presence: true,
//         email: {
//             message: "is not valid"
//         }

//     },
//     'form[phone]': {
//         presence: true,
//         /*format: {
//            pattern: "/\\(?([0-9]{3})\\)?([ .-]?)([0-9]{3})\\2([0-9]{4})/",
//         }*/
//     },
//     'form[website]': {
//         url: true
//     },
//     'form[message]': {
//         presence: true,
//     }
// };

class applicationForm {
    constructor(container){
        this.container = container;
        // this.input = this.container.find('.form-control');
        // this.label = this.container.find('.form-label');
        this.submit = this.container.find('[data-application-submit]')
        this.bindEvents();
    }
    bindEvents(){
        // $(this.input).on('change', this.validateForm.bind(this));
        //$(this.submit).on('click', this.checkForm.bind(this));
        $(this.container).on('submit', this.sendForm.bind(this));
    }
    // validateForm(target){
    //     let errors = validate(this.container, constraints) || {},
    //         input = $(target.currentTarget),
    //         name = input.prop('name');

    //     this.showErrorsForInput(input, errors[name]);
    // }
    // showErrorsForInput(input, errors){
    //     let formGroup = $(input).closest('.form-group'),
    //         messages = $(formGroup).find('.error').css("display", "block"),
    //         that = this;


    //     this.resetFormGroup(formGroup);
    //     this.addError(messages, []);
    //     if(errors){
    //         $(formGroup).addClass("is-error");
    //         this.addError(messages, errors);
    //     }
    //     else {
    //         $(formGroup).addClass("is-success").find('.error').css("display", "none");
    //     }
    // }
    // resetFormGroup(formGroup){
    //     $(formGroup).removeClass("is-error").removeClass("is-success");
    //     this.label = this.container.find('.form-label').css("display", "flex");
    // }
    // addError(messages, error){
    //     error = error.toString();
    //     error = error.replace("Form[","").replace(']','');
    //     error = error.charAt(0).toUpperCase() + error.slice(1);
    //     $(messages).text(error);
    // }
    // showErrors(form, errors){
    //     let that = this;
    //     $(this.input).each(function(){
    //         that.showErrorsForInput($(this), errors && errors[$(this)[0].name])
    //     });
    // }
    checkForm(){
        // let errors = validate(this.container, constraints) || {};
        // this.showErrors(this.container, errors || {});
        // if (Object.keys(errors).length === 0){
            
            this.sendForm();
        // }
    }
    sendForm(e){
        e.preventDefault();
        let form = this.container,
            url = form[0].attributes['action'].value,
            alertSuccess = form.find('.alert--success'),
            alertDanger = form.find('.alert--danger'),
            alert = form.find('.alert');

        $.ajax({
            url: url,
            type: "post",
            data: new FormData(form[0]),
            processData: false,
            contentType: false,
            success: function (data, status)
            {
                let response = JSON.parse(data);
                if(typeof response['captcha'] !== 'undefined' && response['captcha'] === true) {
                    form.find('.g-recaptcha').siblings('.text-danger').removeClass('is-hidden');
                }
                else if (data && data != "captcha_error"){
                    alertDanger.addClass('is-hidden');
                    alertSuccess.removeClass('is-hidden');
                    $('html,body').animate({
                        scrollTop: alert.offset().top - 100
                    }, 300);
                    form[0].reset();
                    form.find('.form-control__uploaded-file').text('');
                    form.find('.g-recaptcha').siblings('.text-danger').addClass('is-hidden');
                    setTimeout(function(){
                        alert.addClass('is-hidden');
                    }, 10000);
                }               
            },
            error: function (xhr, desc, err)
            {
                $('html,body').animate({
                    scrollTop: alert.offset().top - 100
                }, 300);
                form.find('.g-recaptcha').siblings('.text-danger').addClass('is-hidden');
                alertDanger.removeClass('is-hidden');
            }
        });      
    }
}

function initDataSelectors() {
    $('[data-application-form]').each(function () {
        new applicationForm($(this));
    });
}

const init = {
    initDataSelectors,
};
export default init;
