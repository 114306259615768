const SELECTORS = {
    btn: '[data-coupon-copy-btn]',
    code: '[data-coupon-text]',
    tooltip: '[data-coupon-tooltip]',
    copiedtext: '[data-coupon-copied-text]'
}

class couponCopy{
    constructor(container){ 
        this.container = container;
        this.btn = this.container.find(SELECTORS.btn);
        this.code = this.container.find(SELECTORS.code);
        this.tooltip = this.container.find(SELECTORS.tooltip);
        this.copiedtext = this.container.find(SELECTORS.copiedtext)
        this.bindEvents();
    }
    bindEvents(){
        $(this.btn).on('click', this.copyCode.bind(this));
        $(this.btn).on('mouseleave', this.changeTooltipTxt.bind(this));
    }
    copyCode(){
        navigator.clipboard.writeText(this.code[0].innerText);

        if(this.tooltip[0]) {
            this.tooltip[0].innerText = this.tooltip[0].attributes['data-click-text'].value;
        }

        if(this.copiedtext[0]) {
            $(this.copiedtext[0]).removeClass('hidden')
        }
    }
    changeTooltipTxt(){
        if(this.tooltip[0]) {
            this.tooltip[0].innerText = this.tooltip[0].attributes['data-default-text'].value;
        }
    }
}   

function initDataSelectors() {
    $('[data-coupon]').each(function () {
        new couponCopy($(this));
    });
}

const init = {
    initDataSelectors,
};
export default init;