import Swiper from '../../../assets-ui/js/plugins/swiper';

class proofSlider{
    constructor(container){
        this.container = container;
        this.initSlider();
    }
    initSlider(){
        if(this.container.find('.logos--wrapper-autoplay').length > 0) {
            if(this.container.find('.proof--reverse').length > 0){
                this.logosSlider = new Swiper(this.container, {
                    initialSlide: 0,
                    slidesPerView: 4,
                    wrapperClass: 'proof--wrapper',
                    loop: true,
                    freeMode: true,
                    freeModeMomentum: false,
                    autoplay: false,
                    spaceBetween: 0,
                    breakpoints: {
                        1387: {
                            slidesPerView: 4,
                        },
                        751: {
                            slidesPerView: 3,
                            initialSlide: 2,
                        },
                        513: {
                            slidesPerView: 2,
                        }
                    }
                });
            }else{
                this.logosSlider = new Swiper(this.container, {
                    initialSlide: 0,
                    slidesPerView: 4,
                    wrapperClass: 'proof--wrapper',
                    loop: true,
                    freeMode: true,
                    freeModeMomentum: false,
                    autoplay:false,
                    spaceBetween: 0,
                    breakpoints: {
                        1387: {
                            slidesPerView: 4,
                        },
                        751: {
                            slidesPerView: 3,
                            initialSlide: 2,
                        },
                        513: {
                            slidesPerView: 2,
                        }
                    },
                    on: {
                        init: function () {
                            this.setTranslate(this.translate - this.width / 2); // Przesuń o pół slajdu
                        }
                    }
                });
            }
        }
    }
}

export default{
    init(){
        $('.swiper-proof').each(function(){
            new proofSlider($(this));
        });
    }
}

