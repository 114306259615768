const tldContainer = { 
    init: function() {
        if($('[data-tld-holder]')[0]) {

            const holder = $('[data-tld-holder]')[0]

            const tldText = $('[data-tld-text]')[0]

            const holderWidth = holder.clientWidth

            const textContent = tldText.textContent

            var calculatedFontSize = holderWidth / (tldText.textContent.length - 1) 

            if(calculatedFontSize > 140) {
                calculatedFontSize = 140

            } else if(calculatedFontSize < 48) {
                calculatedFontSize = 48
            }

            $(tldText).css('font-size', calculatedFontSize)
        }
    }
}

export default tldContainer