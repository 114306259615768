import 'babel-polyfill';

//navs
import navs from './../../assets-ui/js/components/navs/navs.js';
import './../../assets-ui/js/components/navs/tabs.js';
import switcher from './../../assets-ui/js/components/navs/switcher.js';

//forms
import './../../assets-ui/js/components/forms/forms.js';
import selects from './../../assets-ui/js/components/forms/selects.js';
import selectInput from './../../assets-ui/js/components/forms/select-input.js';
import checkAll from './../../assets-ui/js/components/forms/check-all.js';
import rangeSlider from './../../assets-ui/js/components/forms/range-slider.js';

//dropdowns
import dropdown from './../../assets-ui/js/components/dropdowns/dropdown.js';
import tooltips from './../../assets-ui/js/components/dropdowns/tooltips.js';

//modals
import './../../assets-ui/js/components/modals/modal.js';
import './../../assets-ui/js/components/modals/bootstrap-alert.js';
import notification from './../../assets-ui/js/components/modals/notification.js';

//other
import contentSlider from './components/content-slider.js';
import offCanvas from './../../assets-ui/js/components/off-canvas.js';
import scrollTo from './../../assets-ui/js/components/scroll-to.js';
import './../../assets-ui/js/components/collapse.js';
import sticky from './../../assets-ui/js/components/sticky.js';
import filter from './components/filter.js';

import mobileFooter from './components/mobile-footer';
import mobileNav from './components/mobile-nav';
import StickyNavbar from './components/sticky-navbar';
import testimonailSlider from './components/testimonials-slider';
import mobileSlider from './components/plan-slider.js';
import logoSlider from './components/logos-slider';
import featureSlider from './components/features-slider';
import reviewSlider from './components/reviews-slider';
import pricingTable from './components/pricing-table';
import screenSlider from './components/screen-slider';
import pricingUpdater from './components/pricing-updater';
import pricingPromoUpdater from './components/pricing-promo-updater';
import validateForm from './components/contact-validate';
import applicationForm from './components/application-form';
import './components/sticky-plan-header';
import list from './components/offer-list';
import './components/domains-table';
import animations from './components/animations/index.js';
import './components/other';
import logoDbSlider from './components/logos-slider-db';
import socialProofSlider from './components/social-proof-slider';
import bannerPromo from './components/banner-promo';
import bannerDomain from './components/banner-domains'
import './components/earnings-calculator';
import couponCopy from './components/coupon-copy';
import websitebuilderSlider from './components/websitebuilder-slider';
import cookieBox from './components/cookie-box';
import './components/promo-counter'
import './components/promo-counter-landing-page'
import './../../assets-ui/js/components/dropdowns/package-dropdown.js'

import tldContainer from './components/tldCOntainer.js'
import managedWpSlider from './components/managed-wp-slider.js'

$(document).ready(function () {
    //Nav
    navs.initDataSelectors();

    //Dropdown
    dropdown.initDataSelectors();

    //Tooltips
    tooltips.initDataSelectors();

    //Content Slider
    contentSlider.initDataSelectors();

    //Selects
    selects.initDataSelectors();

    //Check all
    checkAll.initDataSelectors();

    //Sticky
    sticky.initDataSelectors();

    //Scroll To
    scrollTo.initDataSelectors();

    //Off Canvas
    offCanvas.initDataSelectors();


    //Switcher
    switcher.initDataSelectors();

    //Range Slider
    rangeSlider.initDataSelectors();

    validateForm.initDataSelectors();

    applicationForm.initDataSelectors();

    couponCopy.initDataSelectors();

    websitebuilderSlider.init();

    animations.init();
    
    filter.init();
    
    bannerDomain.init()
    
    tldContainer.init()

    managedWpSlider.initDataSelectors();
});


//****** Add plugins to juqery interface ******

//nav
navs.initJqueryPlugin();

//Dropdown
dropdown.initJqueryPlugin();

//Tooltips
tooltips.initJqueryPlugin();

//Content Slider
contentSlider.initJqueryPlugin();

//Switcher
switcher.initJqueryPlugin();

//Selects
selects.initJqueryPlugin();

//Check all
checkAll.initJqueryPlugin();

//Sticky
sticky.initJqueryPlugin();

//Scroll To
scrollTo.initJqueryPlugin();

//Off Canvas
offCanvas.initJqueryPlugin();

//Range Slider
rangeSlider.initJqueryPlugin();

//Notification
notification.init();

mobileNav.init();

StickyNavbar.init();

mobileFooter.init();

testimonailSlider.init();

mobileSlider.init();

logoSlider.init();

featureSlider.init();

reviewSlider.init();

pricingTable.init();

screenSlider.init();

pricingUpdater.init();

pricingPromoUpdater.init();

list.init();

logoDbSlider.init();

socialProofSlider.init();

bannerPromo.init();

cookieBox.init();