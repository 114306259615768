import Swiper from '../../../assets-ui/js/plugins/swiper';

class testimonailSlider {
    constructor(container) {
        this.container = container;
        this.initSlider();
    }
    initSlider() {
        this.testimonialsSlider = new Swiper('.swiper-testimonials', {
            pagination: {
                el: '.testimonials-pagination',
                type: 'bullets',
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            cssMode: true,
            slidesPerView: 1,
            centeredSlides: true,
            loop: true,
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            breakpoints: {
                1011: {
                    spaceBetween: 200,
                },
                751: {
                    spaceBetween: 700,
                }
            }
        });

    }
}
export default {
    init() {
        new testimonailSlider($('.swiper-testimonials'));
    }
}
