

class pricingUpdater {
    constructor(container) {
        this.container = container;
        this.tabs = this.container.find('[data-promo-change-pricing]');
        this.activeItem = this.container.find('.nav .nav__item.is-active a[data-promo-change-pricing]');
        this.initUpdater();
        this.bindEvents();
    }
    initUpdater() {
        let currentPricing = this.activeItem[0].dataset.promoChangePricing;
        this.changePricing(currentPricing);
    }
    bindEvents(){
        let that = this;
  
        this.tabs.on('click', function(){
            let cycle = $(this)[0].dataset.promoChangePricing;
            that.changePricing(cycle);
        });  

    }
    changePricing(cycle){
        const prices = this.container.find('[data-promo-price]');
        const pricingPeriod = this.container.find('[data-promo-pricing-period]');
        const pricesOld = this.container.find('[data-promo-price-old]');
        const priceDiscount = this.container.find('[data-promo-price-discount]');
        const planLinks = this.container.find('[data-promo-plan-link');
        const planDesc = this.container.find('[data-promo-plan-description');
        

        for (let i = 0; i < prices.length; i++) {
            
            if ($(prices[i]).data('promo-price')[cycle - 1] == "Not Available"){
                $(prices[i]).closest('.feature').addClass('feature--disabled');
                $(prices[i]).closest('.pt__row-content').addClass('not-available');
                $(prices[i]).closest('.plan__header').addClass('plan__header--disabled');
            }
            else{
                $(prices[i]).closest('.feature').removeClass('feature--disabled');
                $(prices[i]).closest('.pt__row-content').removeClass('not-available');
                $(prices[i]).closest('.plan__header').removeClass('plan__header--disabled');
                prices[i].textContent = $(prices[i]).data('promo-price')[cycle - 1];
            }
        }

        for (let i = 0; i < pricingPeriod.length; i++) {
            pricingPeriod[i].textContent = $(pricingPeriod[i]).data('promo-pricing-period')[cycle - 1];
        }

        for (let i = 0; i < pricesOld.length; i++) {
            pricesOld[i].textContent = $(pricesOld[i]).data('promo-price-old')[cycle - 1];
        }

        for (let i = 0; i < priceDiscount.length; i++) {
            if (parseInt($(priceDiscount[i]).data('promo-price-discount')[cycle - 1]) === 0){
                $(priceDiscount[i]).closest('.pricing__discount').addClass('is-hidden');
            }
            else{
                $(priceDiscount[i]).closest('.pricing__discount').removeClass('is-hidden');
                priceDiscount[i].textContent = $(priceDiscount[i]).data('promo-price-discount')[cycle - 1];
            }   
        }

        for (let i = 0; i < planLinks.length; i++) {
            planLinks[i].href = $(planLinks[i]).data('href')+$(planLinks[i]).data('promo-plan-link')[cycle - 1];
        }
        for (let i = 0; i < planDesc.length; i++) {
            planDesc[i].textContent = $(planDesc[i]).data('promo-plan-description')[cycle - 1];
        }
    }
}

export default {
    init() {
        $('[data-promo-pricing-container]').each(function(){
            new pricingUpdater($(this));
        });
    },
};
