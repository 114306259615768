import enquire from 'enquire';


const breakpoints = {
    xs: 0,
    sm: 514,
    md: 752,
    lg: 1012,
    xl: 1232
};

let activeViewport = 'desktop'
function enquireRegister(min, max, options) {
    let mediaQuery = ``;
    let deferSetup = options.deferSetup;

    if (min && max) {
        mediaQuery = `screen and (min-width:${breakpoints[min]}px) and (max-width:${breakpoints[max]}px)`
    }
    if (min && !max) {
        mediaQuery = `screen and (min-width:${breakpoints[min]}px)`
    }

    if (!min && max) {
        mediaQuery = `screen and (max-width:${breakpoints[max]}px)`
    }

    if (options.deferSetup) {
        deferSetup = options.deferSetup;
    }

    enquire.register(mediaQuery, {
        match: function () {
            if (typeof options.match === 'function') {
                options.match();
            }
        },
        unmatch: function () {
            if (typeof options.unmatch === 'function') {
                options.unmatch();
            }
        },
        setup: function () {
            if (typeof options.setup === 'function') {
                options.setup();
            }
        },
        deferSetup: deferSetup,
        destroy: function () {
            if (typeof options.destroy === 'function') {
                options.destroy();
            }
        }
    });
}

function checkViewport(){

    enquireRegister('xs', 'md', {
        match(){
            activeViewport  = 'mobile'
        }
    })

    enquireRegister('md', 'lg', {
        match(){
            activeViewport  = 'tablet'
        }
    })

    enquireRegister('lg', null, {
        match(){
            activeViewport  = 'desktop'
        }
    })
}

checkViewport();
export default {
    between: function (min, max, options) {
        enquireRegister(min, max, options)
    },
    down: function (val, options) {
        enquireRegister(null, val, options)
    },
    up: function (val, options) {
        enquireRegister(val, null, options)
    },
    is(value){
        if(value == activeViewport){
            return true
        }else{
            return false
        }
    }
}





// breakpoint.between('xs', 'sm', {
//     match(){
//       console.log('match - xs - sm')
//     },
//     unmatch(){
//       console.log('unmatch - xs - sm')
//     },
//     setup(){
//       console.log('setup  - xs - sm')
//     },
//     destroy(){
//       console.log('destroy  - xs - sm')
//     },
//     deferSetup: true
//   });

//   breakpoint.up('lg', {
//     match(){
//       console.log('match up lg')
//     },
//     unmatch(){
//       console.log('unmatch up lg')
//     },
//     setup(){
//       console.log('setup  up lg')
//     },
//     destroy(){
//       console.log('destroy  up lg')
//     },
//     deferSetup: true
//   });

//   breakpoint.down('sm', {
//     match(){
//       console.log('match down sm')
//     },
//     unmatch(){
//       console.log('unmatch down sm')
//     },
//     setup(){
//       console.log('setup  down sm')
//     },
//     destroy(){
//       console.log('destroy  down sm')
//     },
//     deferSetup: true
//   });
