import util from './../../../assets-ui/js/components/util.js';

const Default = {
    loop: true,
    initialSlide: 0
}

class Filter {
    constructor() {
        this.container = $('[data-filter-container]');
        if(!this.container.length){
            return;
        }

        this.cacheDOM();
        this.getConfig();
        this.bindEvents();
        this.initSlider();

        this.activeFilter = 'all';
    }
    cacheDOM() {
        this.slider = this.container.find('[data-category-wrapper]');
        this.slides = this.slider.find('[data-category]');

        this.slidesWrapper = this.slides.parent();
        this.filtersContainer = this.container.find('[data-filters]');
        this.filters = this.container.find('[data-filter]');

    }
    getConfig(){
        let dataConfig = this.container.data();

        if (dataConfig.options) {
            this.dataOptions = util.parseDataOptions(dataConfig.options);
        } else {
            this.dataOptions = {};
        }

        this.config = $.extend({}, Default, dataConfig, this.dataOptions, this.options);

    }
    bindEvents() {

        this.filters.on('click', event => this.handleFilterClick(event));
        $(window).on('resize', event => this.handleResize(event));

    }
    initSlider() {

    }
    disableSlider() {

    }
    handleFilterClick(event) {

        let item = $(event.currentTarget);
        let filterName = item.data('filter');
        event.preventDefault();

        this.filterSlides(filterName);
    }
    injectNewSlides(newSlides){

        this.disableSlider();

        if(newSlides){

            this.slidesWrapper.empty();
            this.slidesWrapper.prepend(newSlides);

        }else{

            this.slidesWrapper.empty();
            this.slidesWrapper.prepend(this.slides);

        }

        if (this.slidesWrapper.innerWidth() <= this.getSlidesWidth()) {

            this.initSlider();

        }
    }
    filterSlides(filterName) {

        if (filterName == this.activeFilter) return;

        this.activeFilter = filterName;

        this.filtersContainer.find('.is-active').removeClass('is-active');
        this.filtersContainer.find(`[data-filter="${filterName}"]`).parent().addClass('is-active');

        if (filterName == 'all') {
            this.injectNewSlides();
            return;
        }


        let newSlideIndexes = [];

        this.slides.each(function (index, element) {

            let $element = $(element);
            let category = $element.data('category');

            if (category == filterName) {
                newSlideIndexes.push(index);
            }

        });

        let newSlides = this.slides.filter((index) => {

            if (newSlideIndexes.includes(index)) {
                return true
            }

        });

        this.injectNewSlides(newSlides);

    }
    getSlidesWidth() {
    }
}

export default {
    init() {
        new Filter();
    }
}
