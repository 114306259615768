import Swiper from '../../../assets-ui/js/plugins/swiper';

class mobileSlider {

    constructor(container) {
        this.container = container;
        this.tabNav = this.container.find('[data-js-plan-tab]');
        this.tab = this.container.find('[data-js-plan]');
        this.jsSlider = this.container.find('[data-js-plan-slider]');
        this.slider = [];
        this.bindEvents();
    }

    bindEvents() {
        var that = this;
        this.tab.each(function (index) {
            $(this).find('[data-js-plan-prev]').addClass('js-plan-prev-' + index);
            $(this).find('[data-js-plan-next]').addClass('js-plan-next-' + index);
            that.initSlider(index);
            if (document.querySelector('.tabs--plans')) {
                let tabs = [...document.querySelectorAll('.tabs--plans .content-slider__item a')]
                tabs.map(singleTab => {
                    singleTab.addEventListener('click', () => {

                        that.initSlider(index);
                    })
                })
            }

            $('.js-plan-prev-' + index).on('click', function (e) {
                that.slider[index].slidePrev();    
                $('.js-plan-next-' + index).removeClass('is-disabled');   
                let slidesCount = $(this).closest('.section').find('.tab-pane.is-active .swiper-wrapper .plan__header.swiper-slide').length - 1;     
                if (slidesCount && slidesCount != 'undefined'){
                    setTimeout(function(){
                        $(that.slider[index].slides).each(function(){
                            if ($(this).hasClass('swiper-slide-active')){
                                if ($(this).index() == 0){
                                    $('.js-plan-prev-' + index).addClass('is-disabled');
                                }
                            }
                        })
                    }, 10);
                }
            })
            $('.js-plan-next-' + index).on('click', function (e) {
                that.slider[index].slideNext();     
                $('.js-plan-prev-' + index).removeClass('is-disabled');
                let slidesCount = $(this).closest('.section').find('.tab-pane.is-active .swiper-wrapper .plan__header.swiper-slide').length - 1;     
                if (slidesCount && slidesCount != 'undefined'){
                    setTimeout(function(){
                        $(that.slider[index].slides).each(function(){
                            if ($(this).hasClass('swiper-slide-active')){
                                if ($(this).index() == slidesCount){
                                    $('.js-plan-next-' + index).addClass('is-disabled');
                                }
                            }
                        })
                    }, 10);
                }
            })
        });
        this.tabNav.on('click', function () {
            that.slider[1].update(); 
        });
        $('.content-slider__item [data-toggle="lu-tab"]').on('show.bs.tab', function(){
            $('.js-next-plan, .js-previous-plan').removeClass('is-disabled');
        });
    }

    initSlider(index) {
        var that = this;
        let slideWidths;

        if (document.querySelector('.tabs--plans')) {
            slideWidths = $('[data-plan-tab].is-active .plan-wrapper').data('slideWidth');
        }
        else {
            slideWidths = this.container.find('[data-slide-width]').data('slideWidth');
        }



        let planRows =  this.container.find('.swiper-slide')

        if(!slideWidths) {
            slideWidths = ['231','231', '231', '163', '163']
        }

        this.slider[index] = new Swiper(that.jsSlider.eq(index), {
            speed: 400,
            slidesPerView: planRows.length,
            paginationClickable: !0,
            spaceBetween: 0,
            pagination: !1,
            effect: !1,
            resistance: !0,
            width: (slideWidths[0] * planRows.length),
            resistanceRatio: 0,
            wrapperClass: 'plan-wrapper',
            nextButton: '.js-plan-next-' + index,
            prevButton: '.js-plan-prev-' + index,
            breakpoints: {
                1387: {
                    slidesPerView: 1,
                    width: slideWidths[1]
                },
                1011: {
                    slidesPerView: 1,
                    width: slideWidths[2]
                },
                751: {
                    slidesPerView: 1,
                    width: slideWidths[3]
                },
                513: {
                    slidesPerView: 1,
                    width: slideWidths[4]
                }
            }
        })
    }
}

export default {
    init() {
        $('[data-js-plan-container]').each(function () {
            new mobileSlider($(this));
        });
    }
};
