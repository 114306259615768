import Swiper from '../../../assets-ui/js/plugins/swiper';

class websitebuilderSlider {
    constructor(container) {
        this.container = container;
        this.initSlider(this.container);
    }
    initSlider(container) {
        this.Slider = new Swiper(container, {
            centeredSlides: true,
            loop: true,
            slidesPerView: 'auto',
            height: 338,
            loopedSlides: 4,
            init: false
        });
    }
}
export default {
    init() {
        const array = $('.swiper-websitebuilder')
        if(array.length > 0) {
            let  sliders = []
            for (let i = 0; i < array.length; i++) {
                const element = array[i];
                sliders.push(new websitebuilderSlider(element))
            }

            console.log(sliders)

            sliders[0].Slider.init()
            sliders[1].Slider.init()

            $('[data-slider-init]').click(e => {
                const target = e.currentTarget.dataset.sliderInit *2
                console.log(target)

                for (let i = 0; i < sliders.length; i++) {
                    const slider = sliders[i];
                    console.log(slider)

                }

                sliders[target].Slider.init()
                sliders[target + 1].Slider.init()
            })
        }
    }
}
