
import selectize from 'selectize';
import util from './../util.js';

const SELECTORS = {
    select: 'select',
    rawSelect: '.form-control--basic'
};

const Default = {
    maxItems: 1,
    removeButton: false,
    resotreOnBackspace: false,
    dragAndDrop: false,
    closeAfterSelect: true
};

class Select{
    constructor(element, options){

        this.element = element;
        this.element.trigger('selectValue')

        this.options = options;
        this.getConfig();
        this.directionDetector();
        this.betterFocusPlugin();
        this.additionalPlugins();
        this.initPlugin();

    }
    initPlugin(){
        let plugins = this.additionalPlugins();

        this.select = this.element.selectize({
            placeholder: '',
            maxItems: this.config.maxItems,
            createOnBlur: false,
            plugins: plugins,
            copyClassesToDropdown: false,
            allowEmptyOption: false,
            closeAfterSelect: this.config.closeAfterSelect,
            onInitialize: () => {
                this.onInit();
            },
            onChange: (value) => {
                this.onChange(value);
            },
            onFocus: () => {
                this.onFocus();
            },
            onBlur: () => {
                this.onBlur();
            }
        });
        this.selectize = this.select[0].selectize;
    }
    getConfig(){
        var dataConfig  = this.element.data();
        if(dataConfig.options){
            this.dataOptions = util.parseDataOptions(dataConfig.options);
        }else{
            this.dataOptions = {};
        }
        this.config = $.extend({}, Default, dataConfig, this.dataOptions, this.options);
    }

    directionDetector(){
        selectize.define('directionDetector', function(options) {
            var self = this;
            this.positionDropdown = (function() {
                var original = self.positionDropdown;
                return function() {
                    this.offset = this.settings.dropdownParent === 'body' ? this.$control.offset() : this.$control.position();
                    this.offset.top += this.$control.outerHeight(true);

                    var controlHeight = this.$control.innerHeight();
                    var controlPositon = $(this.$control).offset().top - $(window).scrollTop() + 100;
                    var dropdownOffset = controlHeight + controlPositon + this.$dropdown.outerHeight(true);

                    if($(window).innerHeight() - dropdownOffset < 0) {
                        this.$dropdown.css({
                            'top': 'auto',
                            'bottom': 8 + controlHeight,
                            'left': this.offset.left,
                            'width': this.$control[0].getBoundingClientRect().width
                        });
                    }else{
                        this.$dropdown.css({
                            'width' : this.$control[0].getBoundingClientRect().width,
                            'top'   : this.offset.top,
                            'left'  : this.offset.left,
                            'bottom': 'auto'
                        });
                    }
                };
            })();
        });
    }
    betterFocusPlugin(){
        selectize.define('betterFocus', function(options) {
            var self = this;
            this.close = (function() {
                var original = self.onFocus;
                return function() {

                    var self = this;
                    var trigger = self.isOpen;

                    if (self.settings.mode === 'single' && self.items.length) {
                        self.hideInput();
                    }

                    self.isOpen = false;
                    self.$dropdown.hide();
                    self.setActiveOption(null);
                    self.refreshState();

                    if (trigger) self.trigger('dropdown_close', self.$dropdown);
                };
            })();
        });
    }
    additionalPlugins(){
        let plugins = ['directionDetector', 'betterFocus'];

        if(this.config.removeButton){
            plugins.push('remove_button');
        }

        if(this.config.resotreOnBackspace){
            plugins.push('restore_on_backspace');
        }

        if(this.config.drag_drop){
            plugins.push('drag_drop');
        }

        return plugins;
    }
    onInit(){
        if (typeof this.config.onInit === 'function') {
            this.config.onInit(value);
        }
    }
    onChange(value){

        if (typeof this.config.onChange === 'function') {
            this.config.onChange(value);
        }
        this.element.trigger('selectValue')
    }
    onFocus(event){
        if (typeof this.config.onFocus === 'function') {
            this.config.onFocus();
        }

    }
    onBlur(){
        if (typeof this.config.onBlur === 'function') {
            this.config.onBlur();
        }
    }
}

function initDataSelectors(){
    $(SELECTORS.select).not(SELECTORS.rawSelect).each(function(){
        new Select($(this));
    });
}

function initJqueryPlugin(){
    $.fn.luSelect = function( options ) {
        return this.each(function() {
            new Select($(this), options);
        });
    };
}

const init = {
    initDataSelectors,
    initJqueryPlugin
};

export default init;

