import List from 'list.js';

class list {
    constructor(container) {
        this.container = container;
        this.initList();
    }
    initList() {
        var offertList = new List('offer-list', {
            valueNames: ['name'],
            page: 6,
            pagination: true,
        });

        offertList.on('updated', function (){

            $( ".pagination--offer .prev, .pagination--offer .next" ).removeClass( "disabled" );

            if(offertList.i <= 1)
            {
                $( ".pagination--offer .prev" ).addClass( "disabled" );


            }

            if(Math.floor(offertList.items.length / offertList.page) - 1 == Math.floor(offertList.i / offertList.page))
            {
                $( ".pagination--offer .next" ).addClass( "disabled" );

            }
        });

        $('.next').on('click', function(e){
            e.preventDefault();
            if(offertList.items.length < offertList.i+1 * offertList.page)
            {
                return;

            }

            offertList.show(offertList.i+1 * offertList.page, offertList.page);
        })

        $('.prev').on('click', function(e){
            e.preventDefault();
            if(offertList.i <= 1)
            {
                return;
            }

            offertList.show(offertList.i-1 * offertList.page, offertList.page)
        })
    }
}
$("#offer-list").on('click','.page',function(e){
    e.preventDefault();
});

export default {
    init() {
        new list($('.offer-list'));
    }
}
