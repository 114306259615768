
class BoxPromotionCounter {
    constructor(data) {
        this.container = data.container

        const remainingTime = this.getRemainingTime()

        this.insertTime(remainingTime)
    }

    getToday() {
        const now = new Date()
        const endDate = now.setHours(23, 59, 59, 99)
    
        return endDate
    }
    
    getRemainingTime() {
        const endDate = this.getToday()
    
        const now = Date.now()

        const remainingTime = endDate - now 
    
        const hours = Math.floor(remainingTime / 3600000)
        const minutes = Math.floor((remainingTime - 3600000 * hours) / 60000)
        const seconds = Math.floor((remainingTime - (3600000 * hours + 60000 * minutes)) / 1000)
    
        const time = {
            hours: hours,
            minutes: minutes,
            seconds: seconds
        }
    
        return time
    }
    
    insertTime(time) {
        const timeBoxes = $(this.container)

        timeBoxes.find('[data-countdown-hours]')[0].innerText = time.hours
        timeBoxes.find('[data-countdown-minutes]')[0].innerText = time.minutes
        timeBoxes.find('[data-countdown-seconds]')[0].innerText = time.seconds
    
        this.initTimer()
    }
    
    initTimer() {
    
        setTimeout(() => {
            const newTime = this.getRemainingTime()
            this.insertTime(newTime)
    
        }, 1000)    
    }
}


const boxPromotionCounter = $('[data-promo-counter]')

for (let i = 0; i < boxPromotionCounter.length; i++) {
    const element = boxPromotionCounter[i];
    new BoxPromotionCounter({container: element})
}