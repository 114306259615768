import Swiper from '../../../assets-ui/js/plugins/swiper';

class logoSlider{
    constructor(container){
        this.container = container;
        this.initSlider();
    }
    initSlider(){
        if(this.container.find('.logos--wrapper-autoplay').length > 0) {

            this.logosSlider = new Swiper(this.container, {
                initialSlide: 0,
                slidesPerView: 7,
                wrapperClass: 'logos--wrapper',
                loop: true,
                freeMode: true,
                speed: 10000,
                freeModeMomentum: false,
                autoplay: {
                    delay: 0,
                   
                },
                spaceBetween: 0,
                breakpoints: {
                    1387: {
                        slidesPerView: 5,
                    },
                    751: {
                        slidesPerView: 3,
                        initialSlide: 2,
                    },
                    513: {
                        slidesPerView: 2,
                    }
                }
            });

        } else {

            this.logosSlider = new Swiper(this.container, {
                initialSlide: 0,
                slidesPerView: 7,
                wrapperClass: 'logos--wrapper',
                loop: true,
                freeMode: true,
                spaceBetween: 30,
                breakpoints: {
                    1387: {
                        slidesPerView: 5,
                    },
                    751: {
                        slidesPerView: 3,
                        initialSlide: 2,
                    },
                    513: {
                        slidesPerView: 2,
                    }
                }
            });
        }
        let that = this;
        $('.logos--wrapper').on('mouseover', function(){
            that.logosSlider.autoplay.stop();
        });
        $('.logos--wrapper').on('mouseleave', function(){
            that.logosSlider.autoplay.start();
        });
    }
}

export default{
    init(){
        new logoSlider($('.swiper-logos'));
    }
}
