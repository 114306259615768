let validate = require("validate.js");

let constraints = {
    'form[Name]': {
        presence: true,
        length: {
            minimum: 3,
            maximum: 20,
        },
    },
    'form[Email]': {
        presence: true,
        email: {
            message: "is not valid"
        }

    },
    'form[Phone]': {
        presence: true,
        /*format: {
           pattern: "/\\(?([0-9]{3})\\)?([ .-]?)([0-9]{3})\\2([0-9]{4})/",
        }*/
    },
    'form[Website]': {
        url: true
    },
    'form[Message]': {
        presence: true,
    }
};

class validateForm {
    constructor(container){
        this.container = container;
        this.input = this.container.find('.form-control');
        this.label = this.container.find('.form-label');
        this.submit = this.container.find('[data-validate-submit]')
        this.bindEvents();
    }
    bindEvents(){
        $(this.input).on('change', this.validateForm.bind(this));
        $(this.submit).on('click', this.checkForm.bind(this));
    }
    validateForm(target){
        let errors = validate(this.container, constraints) || {},
            input = $(target.currentTarget),
            name = input.prop('name');

        this.showErrorsForInput(input, errors[name]);
    }
    showErrorsForInput(input, errors){
        let formGroup = $(input).closest('.form-group'),
            messages = $(formGroup).find('.error').css("display", "block"),
            that = this;


        this.resetFormGroup(formGroup);
        this.addError(messages, []);
        if(errors){
            $(formGroup).addClass("is-error");
            this.addError(messages, errors);
        }
        else {
            $(formGroup).addClass("is-success").find('.error').css("display", "none");
        }
    }
    resetFormGroup(formGroup){
        $(formGroup).removeClass("is-error").removeClass("is-success");
        this.label = this.container.find('.form-label').css("display", "flex");
    }
    addError(messages, error){
        error = error.toString();
        error = error.replace("Form[","").replace(']','');
        error = error.charAt(0).toUpperCase() + error.slice(1);
        $(messages).text(error);
    }
    showErrors(form, errors){
        let that = this;
        $(this.input).each(function(){
            that.showErrorsForInput($(this), errors && errors[$(this)[0].name])
        });
    }
    checkForm(){
        let errors = validate(this.container, constraints) || {};
        this.showErrors(this.container, errors || {});
        if (Object.keys(errors).length === 0){
            
            this.sendForm();
        }
    }
    sendForm(){
        let form = this.container,
            url = form[0].attributes['action'].value,
            alertSuccess = form.find('.alert--success'),
            alertDanger = form.find('.alert--danger'),
            alert = form.find('.alert');

        $.ajax({
           type: "POST",
           url: url,
           data: form.serialize(),
           success: function(data)
           {
                let response = JSON.parse(data);
                if(typeof response['captcha'] !== 'undefined' && response['captcha'] === true) {
                    form.find('.g-recaptcha').siblings('.text-danger').removeClass('is-hidden');
                }
                else if (data && data != "captcha_error"){
                    alertDanger.addClass('is-hidden');
                    alertSuccess.removeClass('is-hidden');
                    $('html,body').animate({
                        scrollTop: alert.offset().top - 100
                    }, 300);
                    form[0].reset();
                    form.find('.is-success').removeClass('is-success');
                    setTimeout(function(){
                        alert.addClass('is-hidden');
                    }, 10000);
                }               
            },
            error: function (xhr, desc, err)
            {
                $('html,body').animate({
                    scrollTop: alert.offset().top - 100
                }, 300);
                form.find('.g-recaptcha').siblings('.text-danger').addClass('is-hidden');
                alertDanger.removeClass('is-hidden');
            }
        });
    }
}

function initDataSelectors() {
    $('[data-validate-form]').each(function () {
        new validateForm($(this));
    });
}

const init = {
    initDataSelectors,
};
export default init;
