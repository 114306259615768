
const bannerDomain = { 
    init: function() {

        if(document.querySelector('[data-domain-input]')) {
            var input = document.querySelector('[data-domain-input]')
            
            const tlds = document.querySelectorAll('[data-domain-box]')

            tlds.forEach(tld => {
                tld.onclick = function(e) {
                    if(!e.target.href) {
                        e.preventDefault()

                        input.value = tld.dataset.domainBox
                        input.focus()

                        input.selectionStart = 0
                        input.selectionEnd = 0
                    }
                }
            });
            

        }
    }
}

export default bannerDomain