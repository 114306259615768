if (document.querySelector('[data-earnings]')) {
    $("[data-custom-earnings]").on("change keyup paste", function(){
        let inputValue = this.value
        let tabs = [...document.querySelectorAll('.section--earnings .nav .nav__item')];
        let minOne = document.querySelector('[data-earnings] [data-min-one]').dataset.minOne;
        let maxOne = document.querySelector('[data-earnings] [data-max-one]').dataset.maxOne;
        let minTwo = document.querySelector('[data-earnings] [data-min-two]').dataset.minTwo;
        let maxTwo = document.querySelector('[data-earnings] [data-max-two]').dataset.maxTwo;
        let minThree = document.querySelector('[data-earnings] [data-min-three]').dataset.minThree;
        let maxThree = document.querySelector('[data-earnings] [data-max-three]').dataset.maxThree;
    
        if (inputValue >= parseInt(minOne) && inputValue <= parseInt(maxOne) || inputValue == '') {
            tabs[0].querySelector('.nav__link').click();
        }
        else if (inputValue >= parseInt(minTwo) && inputValue <= parseInt(maxTwo)) {
            tabs[1].querySelector('.nav__link').click();
        }
        else if (inputValue >= parseInt(minThree) && inputValue <= parseInt(maxThree)) {
            tabs[2].querySelector('.nav__link').click();
        }
        else if (inputValue > parseInt(maxThree)){
            tabs[3].querySelector('.nav__link').click();
        }
    
        let activeTabTotalAmount = document.querySelector('[data-earnings] .tab-pane.is-active [data-total-amount]')
        let activeTabSingleAmount = document.querySelector('[data-earnings] .tab-pane.is-active [data-single-amount]')
        activeTabTotalAmount.textContent = '$' + activeTabSingleAmount.dataset.singleAmount * inputValue;
        if (inputValue == '') {
            [...document.querySelectorAll('[data-earnings] .tab-pane')].map(singleTab => {
                let tabAmount = singleTab.querySelector('[data-total-amount]');
                tabAmount.textContent = tabAmount.dataset.totalAmount;
            })
        }
    })
}
