import anime from 'animejs';


export default {
    refs: {},
    beforeInit(element, config) {
        this.refs['element'] = element;
        this.refs['leftBoxes'] = element.find('[data-animation-left-box]')
        this.refs['centerBox'] = element.find('[data-animation-center-box]')
        this.refs['rightBoxes'] = element.find('[data-animation-right-box]')
    },
    init(element, config) {
        let startDelay = 0;

        let leftBoxesAnimation = anime({
            targets: [...Array.from(this.refs.leftBoxes)],
            translateY: {
                value: [64, 0],
                duration: 1000
            },
            opacity: {
                value: [0, 1],
                duration: 480
            },
            delay: function(el, i, l) {
                return (i * 190) + startDelay;
            },
            easing: 'easeInSine'
        });



        let centerBoxAnimation = anime({
            targets: this.refs.centerBox[0],
            translateX: {
                value: [64, 0],
                duration: 1000
            },
            opacity: {
                value: [0, 1],
                duration: 480
            },
            easing: 'easeInSine'
        });



        let rightBottomBoxesAnimation = anime({
            targets: [...Array.from(this.refs.rightBoxes)],
            translateX: {
                value: [64, 0],
                duration: 1000
            },
            opacity: {
                value: [0, 1],
                duration: 480
            },
            delay: function(el, i, l) {
                return (i * 160) + startDelay;
            },
            easing: 'easeInSine'
        });

    }
}
