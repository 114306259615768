import dataTable from 'datatables.net';

$(document).ready(function() {
    // if ($('#domains-table').length > 0){
    //     let url = $('#domains-table').data('ajax-url'),
    //         currency = $('#domains-table').data('items-currency-prefix');
    //     $.ajax({
    //         type: "get",
    //         url: url,
    //         success: function(data)
    //         {
    //             let response = JSON.parse(data),
    //                 sale = false,
    //                 registerCol = false, 
    //                 transferCol = false, 
    //                 renewCol = false, 
    //                 idprotCol = false, 
    //                 dnsCol = false, 
    //                 emailCol = false;

    //             const output = response.map(({
    //                 extension,
    //                 register,
    //                 renew,
    //                 transfer,
    //                 idprot,
    //                 dns,
    //                 email
    //             }) => {
                    
    //                 if (typeof register === 'object' || typeof renew === 'object' || typeof transfer === 'object' || typeof idprot === 'object' || typeof dns === 'object' || typeof email === 'object'){
    //                     sale = `<label class="label label--secondary sale">Sale</label>`;  
    //                 }
    //                 else{
    //                     sale = false;
    //                 }
    //                 if (register && typeof register === 'object'){
    //                     registerCol = `<td><span class="price--current">${currency}${register.new}</span><span class="price--discount">${currency}${register.old}</span></td>`;
    //                 }
    //                 else if (register){
    //                     registerCol = '<td>'+(register == '-' || register == "-1.00" ? '-': currency+register)+'</td>';
    //                 }
    //                 if (renew && typeof renew === 'object'){
    //                     renewCol = `<td><span class="price--current">${currency}${renew.new}</span><span class="price--discount">${currency}${renew.old}</span></td>`;
    //                 }
    //                 else if (renew){
    //                     renewCol = '<td>'+(renew == '-' || renew == "-1.00" ? '-': currency+renew)+'</td>';
    //                 }
    //                 if (transfer && typeof transfer === 'object'){
    //                     transferCol = `<td><span class="price--current">${currency}${transfer.new}</span><span class="price--discount">${currency}${transfer.old}</span></td>`;
    //                 }
    //                 else if (transfer){
    //                     transferCol = '<td>'+(transfer == '-' || transfer == "-1.00" ? '-': currency+transfer)+'</td>';
    //                 }
    //                 if (idprot && typeof idprot === 'object'){
    //                     idprotCol = `<td><span class="price--current">${currency}${idprot.new}</span><span class="price--discount">${currency}${idprot.old}</span></td>`;
    //                 }
    //                 else if (idprot){
    //                     idprotCol = '<td>'+(idprot == '-' || idprot == "-1.00" ? '-': currency+idprot)+'</td>';
    //                 }
    //                 if (dns && typeof dns === 'object'){
    //                     dnsCol = `<td><span class="price--current">${currency}${dns.new}</span><span class="price--discount">${currency}${dns.old}</span></td>`;
    //                 }
    //                 else if (dns){
    //                     dnsCol = '<td>'+(dns == '-' || dns == "-1.00" ? '-': currency+dns)+'</td>';
    //                 }
    //                 if (email && typeof email === 'object'){
    //                     emailCol = `<td><span class="price--current">${currency}${email.new}</span><span class="price--discount">${currency}${email.old}</span></td>`;
    //                 }
    //                 else if (email){
    //                     emailCol = '<td>'+(email == '-' || email == "-1.00" ? '-': currency+email)+'</td>';
    //                 }

    //                 let row = `<tr><td>${extension}`+(sale ? sale:'')+`</td>`+ 
    //                         (registerCol ? registerCol : '') + 
    //                         (renewCol ? renewCol : '') + 
    //                         (transferCol ? transferCol : '') + 
    //                         (idprotCol ? idprotCol : '') + 
    //                         (dnsCol ? dnsCol : '') + 
    //                         (emailCol ? emailCol : '') + 
    //                         `</tr>`;

    //                 return row;
    //             });
    //             $('#domains-table tbody').html(output).promise().done(function () {
    //                 dt();
    //                 setTimeout(function () {  
    //                     $('#domains-table').closest('.table-container').removeClass('is-invisible');
    //                     $('#domains-table').closest('.table-container').siblings('.preloader-container').addClass('is-hidden');
    //                 }, 500);
    //             });
    //         }
    //     });
    // }

    if ($('#domains-reseller-table').length > 0){
        let url = $('#domains-reseller-table').data('ajax-url'),
            currency = $('#domains-reseller-table').data('items-currency-prefix');
        $.ajax({
            type: "get",
            url: url,
            success: function(data)
            {
                console.log(data);
                let slab1Col = false, 
                    slab2Col = false, 
                    slab3Col = false;
                   
                const output = data.columns.map(({
                    tld,
                    slab1,
                    slab2,
                    slab3
                }) => {
                    
               
                    if (slab1){
                        slab1Col = '<td>'+(slab1 == '-' || slab1 == "-1.00" ? '-': slab1)+'</td>';
                    }
                
                    if (slab2){
                        slab2Col = '<td>'+(slab2 == '-' || slab2 == "-1.00" ? '-': slab2)+'</td>';
                    }

                    if (slab3){
                        slab3Col = '<td>'+(slab3 == '-' || slab3 == "-1.00" ? '-': slab3)+'</td>';
                    }

                    let row = `<tr><td>${tld}</td>`+ 
                        (slab1Col ? slab1Col : '') + 
                        (slab2Col ? slab2Col : '') + 
                        (slab3Col ? slab3Col : '') + 
                        `</tr>`;

                    return row;
                });
                $('#domains-reseller-table tbody').html(output).promise().done(function () {
                    dt();
                    setTimeout(function () {  
                        $('#domains-reseller-table').closest('.table-container').removeClass('is-invisible');
                        $('#domains-reseller-table').closest('.table-container').siblings('.preloader-container').addClass('is-hidden');
                    }, 500);
                });
            }
        });
    }
});

function dt(){
    //#domains-table, 
    let length = $('#domains-reseller-table').data('items-per-page');

    $('#domains-reseller-table').dataTable( {
        lengthChange: false,
        info: false,
        sDom: '<"top"flp>rt<"bottom"i><"clear">',
        pageLength: length,
        responsive: true,
        columnDefs: [
            { width: 380, targets: 0 }
        ],
        fixedColumns: true,
        language: {
            search: "" ,
            searchPlaceholder: "Search TLDs...",
            paginate: {
                "previous": "<svg width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
                    "    <path d=\"M15 18L9 12L15 6\" stroke=\"#CEC9DF\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n" +
                    "</svg>prev",
                "next": "next <svg width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
                    "    <path d=\"M9 18L15 12L9 6\" stroke=\"#6B4DC4\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n" +
                    "</svg>\n"
            }
        }
    });
}