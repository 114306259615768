if (document.querySelector('.plan')) {
    let planSticky = document.querySelector(".plan-sticky");
    let bounding = planSticky.getBoundingClientRect();
    let planHeaders = [...document.querySelectorAll(".plan__header")];
    let planNavMobile = document.querySelector(".plan__nav");
    let planStickyWrapper = document.querySelector(".section__header-sticky");
    let planHeaderContainer = document.querySelector("#plan-header-sticky .container");
    let planHeaderWrapper = document.querySelector("#plan-header-sticky .container .swiper-wrapper");
    let planInnerWrapper = document.querySelector("#plan-header-sticky .container .swiper-inner-wrapper");
    let planCollapse = [...document.querySelectorAll('#compare-packages-collapse > .list-group__item')];
    let slides = [...document.querySelectorAll('.plan-wrapper .swiper-slide')];
    let tabs = document.querySelector('.tabs--plans');
    let tabsItems = [...document.querySelectorAll('[data-tabs-items] li a')];
    //clone plan header
    function cloneHeaders() {
        if (tabs) {
            planHeaders = [...document.querySelectorAll(".tab-pane.is-active .plan__header")];
            planHeaders.map((clonedHeader, index) => {
                let header = clonedHeader.cloneNode(true);

                if (index >= 1) {
                    header.classList.add("swiper-slide");
                    planHeaderWrapper.appendChild(header);
                } else {
                    planInnerWrapper.appendChild(header);
                }
            });
        }
        else {
            planHeaders.map((clonedHeader, index) => {
                let header = clonedHeader.cloneNode(true);

                if (index >= 1) {
                    header.classList.add("swiper-slide");
                    planHeaderWrapper.appendChild(header);
                } else {
                    planInnerWrapper.appendChild(header);
                }
            });
        }
    }
    
    //change content in columns
    if(tabsItems) {
        document.querySelectorAll('.swiper-slide .tab-pane')
        let slides = [...document.querySelectorAll('.mobile-slider .swiper-slide')]
        tabsItems.map((tabItem, index) => {
            tabItem.addEventListener('click', () => {
                slides.map(slide => {
                    let tabArr = $(slide).find('.tab-pane')
                    tabArr.map((tab_index, item) => {
                        item.classList.remove('is-active');
                        if (tab_index == index) {
                            item.classList.add('is-active');
                        }
                    })
                })

            })
        })
    }

    cloneHeaders();
    if (tabs) {
        let planTabs = [...document.querySelectorAll('.tabs--plans .nav__link')];

        planTabs.map(singleTab => {
            singleTab.addEventListener('click', () => {
                let stickyHeaderTitle = document.querySelector('#plan-header-sticky .swiper-inner-wrapper > .plan__header')
                planHeaderWrapper.innerHTML = ''
                stickyHeaderTitle.remove();
                cloneHeaders();
                changeTextInFirstHeader();
            })
        })
    }

    //clone plan nav mobile
    let clonedNavMobile = planNavMobile.cloneNode(true);
    planHeaderContainer.appendChild(clonedNavMobile);

    function changeTextInFirstHeader() {
        if (document.querySelector('[data-sticky-header]')) {
            let topRow = document.querySelector('[data-sticky-header-top]').innerHTML;
            let bottomRow = document.querySelector('[data-sticky-header-bottom]').innerHTML;
            planInnerWrapper.querySelector(".plan__header:not(.swiper-slide) [data-plan-header-text-top]").innerHTML = topRow;
            planInnerWrapper.querySelector(".plan__header:not(.swiper-slide) [data-plan-header-text-bottom]").innerHTML = bottomRow;
        }
    }

    changeTextInFirstHeader();

    var isInViewport = function (elem) {
            var bounding = elem.getBoundingClientRect();
            return (
                bounding.top <= 30 &&
                bounding.left >= 0 &&
                bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
    };

    function hidePlanHeaders() {
        if (tabs) {
            planHeaders = [...document.querySelectorAll(".tab-pane.is-active .plan__header")];
            planHeaders.map((header) => {
                header.style.opacity = "0";
            });
        }
        else {
            planHeaders.map((header) => {
                header.style.opacity = "0";
            });
        }
    }

    function showPlanHeaders() {
        if (tabs) {
            planHeaders = [...document.querySelectorAll(".tab-pane.is-active .plan__header")];
            planHeaders.map((header) => {
                header.style.opacity = "1";
            });
        }
        else {
            planHeaders.map((header) => {
                header.style.opacity = "1";
            });
        }
    }

    planCollapse.map(singleCollapse => {
        singleCollapse.addEventListener('click', () => {
            let planStickyBottom = document.querySelector("#plan-sticky-bottom");
            planStickyBottom.parentNode.removeChild(planStickyBottom);

            let endOfCollapse = document.createElement("div");
            endOfCollapse.setAttribute("id", "plan-sticky-bottom");

            let openedDropdowns = [...document.querySelectorAll('#compare-packages-collapse .list-group__item[aria-expanded=true]')];
            if (openedDropdowns.length > 0) {
                openedDropdowns[openedDropdowns.length - 1].appendChild(endOfCollapse)
            }
            else {
                document.querySelector('.section__main-plan').appendChild(endOfCollapse)
            }
        })
    })

    window.addEventListener(
        "scroll",
        function (event) {
            let navBigButtons = document.querySelector('.section__main-plan .plan__nav');
            let pricingButtons = [...document.querySelectorAll('.plan__header .btn .btn__text')];
            if (window.innerWidth < 752) {
                pricingButtons.map(pricingButton => {
                    pricingButton.textContent = 'Start Now'
                })
            }
            else {
                pricingButtons.map(pricingButton => {
                    pricingButton.textContent = 'Get Started Now'
                })
            }
            if (tabs) {
                planSticky = document.querySelector('[data-plan-sticky]');
                if (isInViewport(planSticky)) {
                    //detect if scrolled to end of element
                    let planStickyBottom = document.querySelector("#plan-sticky-bottom");

                    hidePlanHeaders();
                    planStickyWrapper.classList.add("visible");
                    planStickyWrapper.style = "";

                    if (navBigButtons) {
                        navBigButtons.classList.add('hidden');
                    }

                    if (planStickyBottom.getBoundingClientRect().top < 0) {
                        planStickyWrapper.style.display = "none";
                    }
                } else {
                    showPlanHeaders();
                    planStickyWrapper.classList.remove("visible");
                    if (navBigButtons) {
                        navBigButtons.classList.remove('hidden');
                    }
                }
            }
            else {
                if (isInViewport(planSticky)) {
                    //detect if scrolled to end of element
                    let planStickyBottom = document.querySelector("#plan-sticky-bottom");
    
                    hidePlanHeaders();
                    planStickyWrapper.classList.add("visible");
                    planStickyWrapper.style = "";
    
                    if (navBigButtons) {
                        navBigButtons.classList.add('hidden');
                    }
    
                    if (planStickyBottom.getBoundingClientRect().top < 0) {
                        planStickyWrapper.style.display = "none";
                    }
                } else {
                    showPlanHeaders();
                    planStickyWrapper.classList.remove("visible");
                    if (navBigButtons) {
                        navBigButtons.classList.remove('hidden');
                    }
                }
            }
        },
        false
    );

    var observer = new MutationObserver(function (mutations) {
        mutations.map((mutationRecord) => {
            let swipePosition = mutationRecord.target.style.transform;
            let swipeAnimation = mutationRecord.target.style.transitionDuration;

            let swipableSections = [...document.querySelectorAll(".plan-wrapper")];
            swipableSections.map((swipableSection) => {
                swipableSection.style.transform = swipePosition;
                swipableSection.style.transitionDuration = swipeAnimation;
            });
        });
    });

    var target = [...document.querySelectorAll(".plan-wrapper")];
    target.map((singleTarget) => {
        observer.observe(singleTarget, {
            attributes: true,
            attributeFilter: ["style"],
        });
    });
}