import Swiper from '../../../assets-ui/js/plugins/swiper';

class reviewSlider {
    constructor(container) {
        this.container = container;
        this.initSlider();
        this.bindEvents();
    }

    bindEvents() {
        var that = this;
        $('.tabs--feedback .nav__item a').on('click', function () {
            let index = $(this).parent().index();
            that.reviewsSlider[index].update();

        })
    }

    initSlider() {
        this.reviewsSlider = new Swiper('.swiper-reviews', {
            initialSlide: 0,
            slidesPerView: 4,
            centeredSlides: false,
            wrapperClass: 'reviews__wrapper',
            buttonDisabledClass: 'is-disabled',
            loop: true,
            on: {
                touchMove: function () {
                    $('.reviews__item').addClass('is-disabled');
                },
                touchEnd: function () {
                    $('.reviews__item').removeClass('is-disabled');
                }
            },
            breakpoints: {
                1650: {
                    slidesPerView: 4,
                    centeredSlides: false,
                },
                1300: {
                    slidesPerView: 3,
                    centeredSlides: false,
                },
                992: {
                    slidesPerView: 2,
                    centeredSlides: false,
                    initialSlide: 1,
                },
                752: {
                    slidesPerView: 1,
                    centeredSlides: false,
                    initialSlide: 1,
                }
            }
        });

    }
}



export default {
    init() {
        new reviewSlider($('.swiper-reviews'));
    }
}
