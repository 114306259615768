import Swiper from '../../../assets-ui/js/plugins/swiper';

class featureSlider{
    constructor(container){
        this.container = container;
        this.initSlider();
    }
    initSlider(){
        this.featuresSlider = new Swiper('.swiper-features', {
            initialSlide: 0,
            slidesPerView: 'auto',
            spaceBetween: 4,
            loop: false,
        });

    }
}

export default{
    init(){
        new featureSlider($('.swiper-features'));
    }
}
