$(document).ready(function(){
    $('[data-package-toggle]').on('click', function(){
        if($(this).closest('[data-package-dropdown]').hasClass('open')){
            $('[data-package-dropdown].open').removeClass('open');
        }else{
            $('[data-package-dropdown].open').removeClass('open');
            $(this).closest('[data-package-dropdown]').addClass('open');
        }
    });

    $('[data-package-toggle]').on('mouseenter', function(){
        if(!is_touch_enabled()){
            $('[data-package-dropdown].open').removeClass('open');
            $(this).closest('[data-package-dropdown]').addClass('open');
        }
    });
    
    $(window).on('click', function() {
        $('[data-package-dropdown].open').removeClass('open');
    });
    
    $('[data-package-toggle]').on('click', function(e) {
        e.stopPropagation();
    });

    function is_touch_enabled() {
        return ( 'ontouchstart' in window ) ||
            ( navigator.maxTouchPoints > 0 ) ||
            ( navigator.msMaxTouchPoints > 0 );
    }
});
