
import List from 'list.js';

const SELECTORS = {
    table: '[data-pricing-table]'
}

class PricingTable {
    constructor(table) {

        this.table = $(table);

        if (!this.table.length) {
            return;
        }

        this.cacheDOM();
        this.bindEvents();
        this.initList();


        this.order = 'asc';
        this.previentOpenCollapse = false;
    }
    cacheDOM() {

        this.sortButtons = this.table.find('[data-sort]');
        this.rows = this.table.find('[data-row]');
        this.showCollapseButtons = this.table.find('[data-show-collapse]');
        this.collapse = this.table.find('[data-collapse]');
    }
    bindEvents() {

        this.rows.on('click', (event) => {

            this.toggleCollapse(event);

        });

        this.sortButtons.on('click', (event) => {

            let item = $(event.currentTarget);
            this.sortButtons.attr('data-sort-order', '');


            if (this.order == 'asc') {

                item.attr('data-sort-order', 'desc');
                this.order = 'desc';
                this.list.sort('js-price', { order: "desc" });

            } else {

                item.attr('data-sort-order', 'asc');
                this.order = 'asc';
                this.list.sort('js-price', { order: "asc" });

            }
        });

    }
    initList() {

        this.list = new List(this.table[0], {
            listClass: 'js-body',
            valueNames: ['js-price']
        });
        this.list.on('sortComplete', () => {

        });

    }
    toggleCollapse(event) {

        let row = $(event.currentTarget).closest('[data-row]');

        if (this.previentOpenCollapse) return;
        this.previentOpenCollapse = true;

        if (row.parent().hasClass('is-open')) {

            row.parent().removeClass('is-open');

            row.parent().find('[data-collapse]').stop().slideUp(300, () => this.previentOpenCollapse = false);

        } else {

            this.rows.parent().removeClass('is-open');
            this.rows.parent().find('[data-collapse]').stop().slideUp(300, () => this.previentOpenCollapse = false);

            row.parent().addClass('is-open');
            row.parent().find('[data-collapse]').stop().slideDown(300, () => this.previentOpenCollapse = false);

        }

    }
}



export default {
    init() {

        $(SELECTORS.table).each(function () {
            new PricingTable(this)
        });

    }
}
