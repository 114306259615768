function parseOption(item) {
  if ('true' === item) {
    return true;
  } else if ('false' === item) {
    return false
  } else if (!isNaN(item * 1)) {
    return parseFloat(item);
  } else {
    return item;
  }
}

const TRANSITION_END = 'transitionend'

const Util = {
  TRANSITION_END: 'bsTransitionEnd',
  parseDataOptions(dataOptions) {
    let options = [];
    let string = dataOptions.split(';');

    string.forEach(function (item, index) {
      let option = item.split(':');

      option = option.map(function (item) {
        return item.trim();
      })
      if (option[0]) {
        options[option[0]] = parseOption(option[1]);
      }
    });

    return options;
  },

  getSelectorFromElement(element) {
    let selector = element.getAttribute('data-target')

    if (!selector || selector === '#') {
      const hrefAttr = element.getAttribute('href')
      selector = hrefAttr && hrefAttr !== '#' ? hrefAttr.trim() : ''
    }

    try {
      return document.querySelector(selector) ? selector : null
    } catch (err) {
      return null
    }
  },

  supportsTransitionEnd() {
    return Boolean(TRANSITION_END)
  },
}



export default Util