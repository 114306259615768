

import noUiSlider from 'nouislider';
import util from './../../../assets-ui/js/components/util.js';


const SELECTORS = {

    container: '[data-managed-wp-container]',
    rangeSlider: '[data-managed-wp-slider]',
    rangeInput: '[data-managed-wp-input]',
    viewValue: '[data-managed-wp-view]',
    incBtn: '[data-managed-wp-inc]',
    decBtn: '[data-managed-wp-dec]',
    ratio: '[data-managed-wp-ratio]',
    package: '[data-package]',
    changePricing: '[data-change-pricing]',
    sliderBox: '[data-managed-wp-box-slider]'

};

const Default = {
    connect: [true, false],
    behaviour: 'tap',
    snap: false,
    range: {},
    step: 1,
    startValue: 0,
    minValue: null,
    maxValue: null,
    ratio: 2
};

class managedWpSlider {
    constructor(container, options) {

        this.container = container;
        this.rangeSlider = this.container.find(SELECTORS.rangeSlider);
        this.rangeInput = this.container.find(SELECTORS.rangeInput);
        this.viewValue = this.container.find(SELECTORS.viewValue);
        this.package = this.container.find(SELECTORS.package);
        this.changePricing = this.container.find(SELECTORS.changePricing);
        this.sliderBox = this.container.find(SELECTORS.sliderBox);

        this.decBtn = this.container.find(SELECTORS.decBtn);
        this.incBtn = this.container.find(SELECTORS.incBtn);
        this.ratio = this.container.find(SELECTORS.ratio);


        this.options = options;

        this.getConfig();
        this.initPlugin();
        this.bindEvents();

    }
    bindEvents() {
        this.decBtn.on('click',()=>{
            this.slider.set( this.slider.get() - this.config.step );
        });

        this.incBtn.on('click',()=>{
            this.slider.set( this.slider.get() + this.config.step );
        });
        this.rangeInput.on('change blur',(event)=>{
            this.slider.set( event.target.value );
        });
        this.changePricing.on('click',()=>{
            console.log();
            this.sliderBox[0].className = 'box__slider ' + event.currentTarget.dataset.changePricing;
        });
        $('.range-slider__value').on('click', (event)=>{
            this.slider.set( event.currentTarget.dataset.value);
        });
    }
    getConfig() {

        this.dataConfig = this.container.data();
        if (this.dataConfig.options) {
            this.dataOptions = util.parseDataOptions(this.dataConfig.options);
        } else {
            this.dataOptions = {};
        }
        this.config = $.extend({}, Default, this.dataConfig, this.dataOptions, this.options);


    }
    setRange() {

        this.range = {};

        if (this.config.minValue) {
            this.range['min'] = this.config.minValue;
        }

        if (this.config.maxValue) {
            this.range['max'] = this.config.maxValue;
        }

        if (this.dataConfig.values) {

            let values = this.dataConfig.values;
            values.map((currentValue, index, array) => {

                if (index == 0) {

                    this.range['min'] = currentValue;

                } else if (index == array.length - 1) {

                    this.range['max'] = currentValue;

                } else {
                    this.range[(index * 100) / array.length + '%'] = currentValue;

                }

            });
        }


    }

    setPipes(){
        
        let wpInstalls = this.dataConfig.wpInstalls,
            wpVisits = this.dataConfig.wpVisits,
            wpStorage = this.dataConfig.wpStorage;

        this.format = {
            to: function(value) {
                return '<span class="pip-wp-installs">'+wpInstalls[Math.round(value-1)]+'</span><span class="pip-wp-visits">'+wpVisits[Math.round(value-1)]+'</span><span class="pip-wp-storage">'+wpStorage[Math.round(value-1)]+'</span>';
            },
            from: function (value) {
                return valuesForSlider.indexOf(Number(value));
            }
        };
    }

    updateValue(value){

    }
    setStartValue() {

        if (this.rangeInput.length) {
            this.startValue = this.rangeInput.first().val();
        } else {
            this.startValue = this.config.startValue;
        }

    }
    initPlugin() {

        this.setStartValue();
        this.setRange();
        this.setPipes();
        this.slider = noUiSlider.create(this.rangeSlider[0], {
            start: this.startValue,
            step: this.config.step,
            snap: this.config.snap,
            connect: this.config.connect,
            range: this.range,
            behaviour: this.config.behaviour,
            format: {
                to: function (value) {
                    return Math.round(value);
                },
                from: function (value) {
                    return Math.round(value);
                }
            },
            pips: { mode: 'steps', format: this.format},
            'cssPrefix': 'range-slider',
            'cssClasses': {
                target: '',
                base: '__base',
                origin: '__origin',
                handle: '__handle',
                handleLower: '--handle-lower',
                handleUpper: '--handle-upper',
                horizontal: '--horizontal',
                vertical: '--vertical',
                background: '--background',
                connects: '__connects',
                connect: '__connect',
                ltr: '--ltr',
                rtl: '--rtl',
                draggable: '--draggable',
                drag: '--state-drag',
                tap: '--state-tap',
                active: 'is-active',
                tooltip: '__tooltip',
                pips: '__pips',
                pipsHorizontal: '--pips-horizontal',
                pipsVertical: '--pips-vertical',
                marker: '__marker',
                markerHorizontal: '--marker-horizontal',
                markerVertical: '--marker-vertical',
                markerNormal: '--marker-normal',
                markerLarge: '--marker-large',
                markerSub: '--marker-sub',
                value: '__value',
                valueHorizontal: '--value-horizontal',
                valueVertical: '--value-vertical',
                valueNormal: '--value-normal',
                valueLarge: '--value-large',
                valueSub: '--value-sub'
            },
        });

        this.slider.on('update', (values, handle) => {
            this.onUpdate(values, handle);
            this.setRatio(values, handle);
        });

        this.slider.on('slide', (values, handle) => {
            this.onSlide(values, handle);
            this.setRatio(values, handle);
        });

        this.slider.on('change', (values, handle) => {
            this.onChange(values, handle);
            this.setRatio(values, handle);
        });
        this.slider.on('set', (values, handle) => {
            this.onSet(values, handle);
            this.setRatio(values, handle);
        });
    }

    onSlide(values, handle) {
        this.rangeInput.val(values);
        this.viewValue.text(values);
        
        

        if (typeof this.config.onSlide === 'function') {
            this.config.onSlide(values, handle);
        }

    }

    onChange(values, handle) {
        
        
        if (typeof this.config.onChange === 'function') {
            this.config.onChange(values, handle);
        }

    }
    onSet(values, handle){
        this.rangeInput.val(values).trigger('keyup');
        this.viewValue.text(values);

        if (typeof this.config.onSet === 'function') {
            this.config.onSet(values, handle);
        }
    }
    onUpdate(values, handle) {

        if (typeof this.config.onUpdate === 'function') {
            this.config.onUpdate(values, handle);
        }
        var value = parseFloat(values[handle]);
        
        var pips = document.querySelectorAll('.range-slider__value');
        pips.forEach(function (pip) {
            pip.classList.remove('is-active');
        });

        pips.forEach(function (pip) {
            if (parseFloat(pip.dataset.value) === value) {
                pip.classList.add('is-active');
            }
        });

        let that = this;
        
        this.package.each(function(){
            let packageNuber = $(this)[0].attributes['data-package'].value;
            if (that.slider.get() == parseInt(packageNuber)){
                $(this)[0].classList.remove('is-hidden');
            }
            else{
                $(this)[0].classList.add('is-hidden');
            }
        });

    }
    setRatio(values, handle) {
        this.ratio.text((parseFloat(this.config.ratio) * parseFloat(this.rangeInput.val())).toFixed(2));
    }
}

function initDataSelectors() {

    $(SELECTORS.container).each(function () {
        new managedWpSlider($(this));
    });

}

const init = {
    initDataSelectors
};

export default init;