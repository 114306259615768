

import noUiSlider from 'nouislider';
import util from './../util.js';


const SELECTORS = {

    container: '[data-range-container]',
    rangeSlider: '[data-range-slider]',
    rangeInput: '[data-range-input]',
    viewValue: '[data-range-view]',
    incBtn: '[data-range-inc]',
    decBtn: '[data-range-dec]',
    ratio: '[data-range-ratio]'
};

const Default = {
    connect: [true, false],
    behaviour: 'tap',
    snap: false,
    range: {},
    step: 1,
    startValue: 0,
    minValue: null,
    maxValue: null,
    ratio: 2
};

class RangeSlider {
    constructor(container, options) {

        this.container = container;
        this.rangeSlider = this.container.find(SELECTORS.rangeSlider);
        this.rangeInput = this.container.find(SELECTORS.rangeInput);
        this.viewValue = this.container.find(SELECTORS.viewValue);


        this.decBtn = this.container.find(SELECTORS.decBtn);
        this.incBtn = this.container.find(SELECTORS.incBtn);
        this.ratio = this.container.find(SELECTORS.ratio);


        this.options = options;

        this.getConfig();
        this.initPlugin();
        this.bindEvents();

    }
    bindEvents() {
        this.decBtn.on('click',()=>{
            this.slider.set( this.slider.get() - this.config.step );
        });

        this.incBtn.on('click',()=>{
            this.slider.set( this.slider.get() + this.config.step );
        });
        this.rangeInput.on('change blur',(event)=>{
            this.slider.set( event.target.value );
        });
    }
    getConfig() {

        this.dataConfig = this.container.data();
        if (this.dataConfig.options) {
            this.dataOptions = util.parseDataOptions(this.dataConfig.options);
        } else {
            this.dataOptions = {};
        }
        this.config = $.extend({}, Default, this.dataConfig, this.dataOptions, this.options);


    }
    setRange() {

        this.range = {};

        if (this.config.minValue) {
            this.range['min'] = this.config.minValue;
        }

        if (this.config.maxValue) {
            this.range['max'] = this.config.maxValue;
        }

        if (this.dataConfig.values) {

            let values = this.dataConfig.values;
            values.map((currentValue, index, array) => {

                if (index == 0) {

                    this.range['min'] = currentValue;

                } else if (index == array.length - 1) {

                    this.range['max'] = currentValue;

                } else {
                    this.range[(index * 100) / array.length + '%'] = currentValue;

                }

            });
        }


    }
    updateValue(value){

    }
    setStartValue() {

        if (this.rangeInput.length) {
            this.startValue = this.rangeInput.first().val();
        } else {
            this.startValue = this.config.startValue;
        }

    }
    initPlugin() {

        this.setStartValue();
        this.setRange();
        this.slider = noUiSlider.create(this.rangeSlider[0], {
            start: this.startValue,
            step: this.config.step,
            snap: this.config.snap,
            connect: this.config.connect,
            range: this.range,
            behaviour: this.config.behaviour,
            format: {
                to: function (value) {
                    return Math.round(value);
                },
                from: function (value) {
                    return Math.round(value);
                }
            },
            'cssPrefix': 'range-slider',
            'cssClasses': {
                target: '',
                base: '__base',
                origin: '__origin',
                handle: '__handle',
                handleLower: '--handle-lower',
                handleUpper: '--handle-upper',
                horizontal: '--horizontal',
                vertical: '--vertical',
                background: '--background',
                connects: '__connects',
                connect: '__connect',
                ltr: '--ltr',
                rtl: '--rtl',
                draggable: '--draggable',
                drag: '--state-drag',
                tap: '--state-tap',
                active: 'is-active',
                tooltip: '__tooltip',
                pips: '__pips',
                pipsHorizontal: '--pips-horizontal',
                pipsVertical: '--pips-vertical',
                marker: '__marker',
                markerHorizontal: '--marker-horizontal',
                markerVertical: '--marker-vertical',
                markerNormal: '--marker-normal',
                markerLarge: '--marker-large',
                markerSub: '--marker-sub',
                value: '__value',
                valueHorizontal: '--value-horizontal',
                valueVertical: '--value-vertical',
                valueNormal: '--value-normal',
                valueLarge: '--value-large',
                valueSub: '--value-sub'
            },
        });

        this.slider.on('update', (values, handle) => {
            this.onUpdate(values, handle);
            this.setRatio(values, handle);
        });

        this.slider.on('slide', (values, handle) => {
            this.onSlide(values, handle);
            this.setRatio(values, handle);
        });

        this.slider.on('change', (values, handle) => {
            this.onChange(values, handle);
            this.setRatio(values, handle);
        });
        this.slider.on('set', (values, handle) => {
            this.onSet(values, handle);
            this.setRatio(values, handle);
        });
    }

    onSlide(values, handle) {
        this.rangeInput.val(values);
        this.viewValue.text(values);
        if (typeof this.config.onSlide === 'function') {
            this.config.onSlide(values, handle);
        }

    }

    onChange(values, handle) {

        if (typeof this.config.onChange === 'function') {
            this.config.onChange(values, handle);
        }

    }
    onSet(values, handle){
        this.rangeInput.val(values).trigger('keyup');
        this.viewValue.text(values);

        if (typeof this.config.onSet === 'function') {
            this.config.onSet(values, handle);
        }
    }
    onUpdate(values, handle) {

        if (typeof this.config.onUpdate === 'function') {
            this.config.onUpdate(values, handle);
        }

    }
    setRatio(values, handle) {
        this.ratio.text((parseFloat(this.config.ratio) * parseFloat(this.rangeInput.val())).toFixed(2));
    }
}

function initDataSelectors() {

    $(SELECTORS.container).each(function () {
        new RangeSlider($(this));
    });

}


function initJqueryPlugin() {


    $.fn.luRangeSlider = function (options) {
        return this.each(function () {
            new RangeSlider($(this), options);
        });
    };

}

const init = {
    initDataSelectors,
    initJqueryPlugin,
};

export default init;
